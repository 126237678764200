import {
	RouterModule,
	ActivatedRoute,
	Routes
}
from '@angular/router';

import {
	Login,
	Register,
	DashboardComponent,
	MembershipComponent,
	ProfileComponent,
	ConsultantComponent,
	BlogComponent,
	BlogPreview,
	EditorComponent,
	StatisticComponent,
	TaxonomyComponent,
	AddTaxonomyComponent,
	EditTaxonomyComponent,
	RevenueComponent,
	AddUserComponent,
	EditUserComponent,
	UserComponent
} from './';

import {
	AuthGuard
} from './pages/shared/auth.guard';
import { PodcastsComponent } from './pages/podcasts/podcasts.component';
import { PodcastPostComponent } from './pages/podcasts/podcast-post/podcast-post.component';
import { PodcastPreview } from './pages/podcasts/podcast-preview';

export const rootRouterConfig: Routes = [
	{
		path: '',
		redirectTo: 'dashboard',
		pathMatch: 'full',
	},
	{
		path: 'dashboard',
		component: DashboardComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'user-manager',
		component: UserComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'taxonomy',
		component: TaxonomyComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'add-taxonomy',
		component: AddTaxonomyComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'edit-taxonomy/:id',
		component: EditTaxonomyComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'add-user',
		component: AddUserComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'edit-user/:id',
		component: EditUserComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'membership-manager',
		component: MembershipComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'consultations',
		component: ConsultantComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'blog',
		component: BlogComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'blog-post',
		component: EditorComponent,
		canActivate: [AuthGuard]
	}, 
	{
		path: 'article/:id',
		component: BlogPreview,
		canActivate: [AuthGuard]
	},
	{
		path: 'podcasts',
		component: PodcastsComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'podcast-post',
		component: PodcastPostComponent,
		canActivate: [AuthGuard]
	}, 
	{
		path: 'podcastArticle/:id',
		component: PodcastPreview,
		canActivate: [AuthGuard]
	}, 
	{
		path: 'statistics',
		component: StatisticComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'revenue',
		component: RevenueComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'profile-edit',
		component: ProfileComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'login',
		component: Login
	},
	{
		path: 'register',
		component: Register
	}
]