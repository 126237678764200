import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ApiHelperService } from '../../shared/api-helper.service';
import * as Constants from '../../../CONSTANTS';

export interface PodcastCardInterface {
  id: number,
  checked: boolean
}

@Component({
  selector: 'podcast-card',
  templateUrl: 'podcast-card.component.html',
  styleUrls: ['./podcast-card.component.scss'],
})

export class PodcastCardComponent {
  constructor(
    private api: ApiHelperService
  ) {
  }

  private base_url = Constants.URL_API;

  @Input() title: string;
  @Input() article: string;
  @Input() image: string;
  @Input() id: number;
  @Input() active: boolean;

  @Output() changeAction = new EventEmitter();
  public selected: boolean = false;



  ngOnInit() {
    this.appendUrl();
  }

  appendUrl() {
    let temp = this.base_url + '/' + this.image;

    this.image = temp;
  }


  changeOnClick(id: number) {
    this.changeAction.emit({ id: id, checked: this.selected });
  }




}