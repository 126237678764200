import {Component, ElementRef, ViewEncapsulation} from '@angular/core';
import {FormGroup, FormControl, FormBuilder, Validators, FormArray} from '@angular/forms';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {ApiHelperService} from '../../shared/api-helper.service';
import {SharedService} from '../../shared/shared.service';
import {IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
import * as Constants from '../../../CONSTANTS';
import {AngularEditorConfig} from '@kolkov/angular-editor';

@Component({
    selector: 'edit-user',
    styleUrls: ['./edit-user.component.scss'],
    templateUrl: './edit-user.component.html'
})

export class EditUserComponent {

    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        minHeight: '5rem',
         //maxHeight: '15rem',
        height: '95rem',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'About Me',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '5',
        fonts: [
            {class: 'arial', name: 'Arial'},
            {class: 'times-new-roman', name: 'Times New Roman'},
            {class: 'calibri', name: 'Calibri'},
            {class: 'comic-sans-ms', name: 'Comic Sans MS'}
        ],
        customClasses: [
            {
                name: 'quote',
                class: 'quote',
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: 'titleText',
                class: 'titleText',
                tag: 'h1',
            },
        ],
        //uploadUrl: this.shared.returnUrl() + '/admin/addContentPhoto',
        uploadWithCredentials: false,
        sanitize: false,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
            ['bold', 'italic'],
            ['fontSize']
        ],

    };
    public userInformation;
    public userCreated;
    public userPhoto;
    public userID;
    private base_url = Constants.URL_API;
    public providerList;
    public areasOF;
    public btype: any;
    public ptype: any;
    public selected: boolean = true;
    public consultation;
    public travel;

    constructor(
        private apiHelper: ApiHelperService,
        private activatedRoute: ActivatedRoute,
        private element: ElementRef,
        private fb: FormBuilder,
        private shared: SharedService,
    ) {

    }


    public form: any = {
        user_id: null, type: "admin", first_name: "", last_name: "", city: "",
        state: "", zipcode: "", address1: "", address2: "", address3: "",
        phone1: "", phone2: "", email: "", password: "",
        facebook: "", instagram: "", googleplus: "", twitter: ""
    };

    public form2: any = {
        website: "", about_me: "", cv_resume: "", specialisation: "", company_name: "",
        certifications: "", education: "", free_consultations: 0, provider_type: null, willing_to_travel: 0,
        licenses: ""
    }

    licenceForm: FormGroup;
    licences: any = [];

    educationForm: FormGroup;
    education: any = [];

    public options_one: Object = {
        placeholderText: 'About Me',
        toolbarButtons: ['bold', 'italic'],
        pasteAllowedStyleProps: ['font-family', 'font-size', 'color', 'font-style'],
        imageEditButtons: ['imageDisplay', 'imageAlign', 'imageInfo', 'imageRemove'],
        charCounterCount: false,
        pluginsEnabled: []
    }



    public options_two: Object = {
        placeholderText: 'Certifications',
        toolbarButtons: ['bold', 'italic'],
        charCounterCount: false,
        pluginsEnabled: []
    }

    public options_three: Object = {
        placeholderText: 'Education',
        toolbarButtons: ['bold', 'italic'],
        charCounterCount: false,
        pluginsEnabled: []
    }


    myDatePickerOptions: IAngularMyDpOptions = {
        dateRange: false,
        dateFormat: 'yyyy-mm-dd'
        // other options are here...
    };


    model: IMyDateModel = null;
    model2: IMyDateModel = null;

    ngOnInit() {
        this.activatedRoute.params.subscribe((params: Params) => {
            let userId = params['id'];
            this.getUserInformations(userId);
            this.form.user_id = userId;
        });

        this.getAreas();
        this.getProviderType();

        $(document).ready(() => {
            setInterval(() => {
                $("a[target='_blank']").hide();
            }, 1000)
        });
        this.model = {isRange: false, singleDate: {jsDate: new Date()}};
        this.model2 = {isRange: false, singleDate: {jsDate: new Date()}};
    }


    initItemRowsLicence(element) {
        return this.fb.group({
            license_type: [element.license_type],
            license_number: [element.license_number],
            name_of_licensing: [element.name_of_licensing]
        });
    }

    initItemRowsLicenceEmpty() {
        return this.fb.group({
            license_type: [''],
            license_number: [''],
            name_of_licensing: ['']
        });
    }

    initItemRowsEducationEmpty() {
        return this.fb.group({
            title: [''],
            from: [''],
            to: [''],
            description: ['']
        });
    }

    addNewRowLicence(licenseList) {
        // control refers to your formarray
        const control = <FormArray> this.licenceForm.controls['licenses'];

        // add new formgroup
        licenseList.forEach(element => {
            control.push(this.initItemRowsLicence(element));
        });
    }

    addNewRowLicenceEmpty() {
        // control refers to your formarray
        const control = <FormArray> this.licenceForm.controls['licenses'];

        // add new formgroup
        control.push(this.initItemRowsLicenceEmpty());
    }

    initItemRowsEducation(element) {
        let dateFrom = element.from.split(' ');
        dateFrom = dateFrom[0].split('-');
        let yearFrom = dateFrom[0];
        let monthFrom = dateFrom[1];
        if (monthFrom.split('') && monthFrom.split('')[0] == '0') {
            monthFrom = monthFrom.split('')[1];
        }

        let dayFrom = dateFrom[2];
        if (dayFrom.split('') && dayFrom.split('')[0] == '0') {
            dayFrom = dayFrom.split('')[1];
        }

        let dateTo = element.to.split(' ');
        dateTo = dateTo[0].split('-');
        let yearTo = dateTo[0];
        let monthTo = dateTo[1];
        if (monthTo.split('') && monthTo.split('')[0] == '0') {
            monthTo = monthTo.split('')[1];
        }

        let dayTo = dateTo[2];
        if (dayTo.split('') && dayTo.split('')[0] == '0') {
            dayTo = dayTo.split('')[1];
        }

        return this.fb.group({
            title: [element.title],
            from: [{date: {year: yearFrom, month: monthFrom, day: dayFrom}}],
            to: [{date: {year: yearTo, month: monthTo, day: dayTo}}],
            description: [element.description]
        });
    }

    addNewRowEducation(educationList) {
        // control refers to your formarray
        const control = <FormArray> this.educationForm.controls['education'];

        // add new formgroup
        educationList.forEach(element => {
            control.push(this.initItemRowsEducation(element));
        });
    }

    addNewRowEducationEmpty() {
        // control refers to your formarray
        const control = <FormArray> this.educationForm.controls['education'];

        // add new formgroup
        control.push(this.initItemRowsEducationEmpty());
    }

    deleteLicense(index) {
        const arrayControl = <FormArray> this.licenceForm.controls['licenses'];
        arrayControl.removeAt(index);
    }

    deleteEducation(index) {
        const arrayControl = <FormArray> this.educationForm.controls['education'];
        arrayControl.removeAt(index);
    }

    getUserInformations(id) {
        let uri = '/admin/getUser/' + id;

        this.apiHelper.get(uri, {}, true)
            .subscribe(res => {
                if (res) {
                    this.userInformation = res;
                    this.form.type = this.userInformation.type;
                    // this.btype = this.userInformation.professional ? this.userInformation.professional.business : 0;
                    if (this.userInformation.professional && this.userInformation.professional.company_name != '') {
                        this.btype = 1;
                    } else {
                        this.btype = 0;
                    }

                    this.ptype = this.userInformation.professional ? this.userInformation.professional : 0;

                    if (this.userInformation.licenses && this.userInformation.licenses.length > 0) {
                        this.licenceForm = this.fb.group({
                            licenses: this.fb.array([this.initItemRowsLicence(this.userInformation.licenses[0])]) // here
                        });

                        this.userInformation.licenses.splice(0, 1);

                        if (this.userInformation.licenses.length) {
                            this.addNewRowLicence(this.userInformation.licenses);
                        }
                    } else {
                        this.licenceForm = this.fb.group({
                            licenses: this.fb.array([this.initItemRowsLicenceEmpty()]) // here
                        });
                    }

                    if (this.userInformation.education && this.userInformation.education.length > 0) {
                        this.educationForm = this.fb.group({
                            education: this.fb.array([this.initItemRowsEducation(this.userInformation.education[0])]) // here
                        });

                        this.userInformation.education.splice(0, 1);

                        if (this.userInformation.education.length) {
                            this.addNewRowEducation(this.userInformation.education);
                        }

                    } else {
                        this.educationForm = this.fb.group({
                            education: this.fb.array([this.initItemRowsEducationEmpty()]) // here
                        });
                    }

                    if (this.userInformation.images.length) {
                        let image: any = document.querySelector('.avatar');
                        image.src = this.base_url + '/' + this.userInformation.images[0].img;
                    }

                    if (this.form.type != 'professional') {
                        this.form.first_name = this.userInformation.first_name;
                        this.form.last_name = this.userInformation.last_name;
                        this.form.city = this.userInformation.city;
                        this.form.state = this.userInformation.state;
                        this.form.zipcode = this.userInformation.zipcode;
                        this.form.address1 = this.userInformation.address1;
                        this.form.address2 = this.userInformation.address2;
                        this.form.address3 = this.userInformation.address3;
                        this.form.phone1 = this.userInformation.phone1;
                        this.form.phone2 = this.userInformation.phone2;
                        this.form.email = this.userInformation.email;
                        this.form.facebook = this.userInformation.facebook;
                        this.form.instagram = this.userInformation.instagram;
                        this.form.googleplus = this.userInformation.googleplus;
                        this.form.twitter = this.userInformation.twitter;
                    } else {
                        this.form.first_name = this.userInformation.first_name;
                        this.form.last_name = this.userInformation.last_name;
                        this.form2.company_name = this.userInformation.professional.company_name;
                        this.form.city = this.userInformation.city;
                        this.form.state = this.userInformation.state;
                        this.form.zipcode = this.userInformation.zipcode;
                        this.form.address1 = this.userInformation.address1;
                        this.form.address2 = this.userInformation.address2;
                        this.form.address3 = this.userInformation.address3;
                        this.form.phone1 = this.userInformation.phone1;
                        this.form.phone2 = this.userInformation.phone2;
                        this.form.email = this.userInformation.email;
                        this.form2.website = this.userInformation.professional.website;
                        this.form2.about_me = this.userInformation.professional.about_me;
                        this.form2.cv_resume = this.userInformation.professional.cv_resume;
                        this.form2.specialisation = this.userInformation.professional.specialisation;
                        this.form2.certifications = this.userInformation.professional.certifications;
                        this.form2.provider_type = this.userInformation.professional.provider_type;
                        this.form2.free_consultations = this.userInformation.professional.free_consultations;
                        this.form2.willing_to_travel = this.userInformation.professional.willing_to_travel;
                        this.form2.availability = this.userInformation.professional.availability;
                        this.form2.provider = this.userInformation.professional.provider;
                        this.form2.business = this.userInformation.professional.business;
                    }

                    setTimeout(() => {
                        // precheck buttons
                        if (this.userInformation.professional) {

                            if (this.btype == 1) {
                                $('.btype1').prop('checked', true);
                                $('.btype0').prop('checked', false);
                            } else {
                                $('.btype0').prop('checked', true);
                                $('.btype1').prop('checked', false);
                            }

                            // precheck checkboxes
                            let tempTypes = this.userInformation.provider_types;

                            $('.checkedPType').each((index, elem: any) => {
                                for (let x = 0; x < tempTypes.length; x++) {
                                    if ($(elem).val() == tempTypes[x].id) {
                                        $(elem).prop('checked', true);
                                    }
                                }
                            });

                            // precheck checkboxes
                            $('.checkedAof').each((index, elem: any) => {
                                $.each(this.userInformation.areas_of_difficulty, function (index2, elem2) {
                                    if ($(elem).val() == elem2.id) {
                                        $(elem).prop('checked', true);
                                    }
                                })
                            });

                            $('.checkedAge').each((index, elem: any) => {
                                $.each(this.userInformation.child_age, function (index2, elem2) {
                                    if ($(elem).val() == elem2.id) {
                                        $(elem).prop('checked', true);
                                    }
                                })
                            });

                            let consRadioYes: any = document.getElementById("consRadioYes");
                            let consRadioNo: any = document.getElementById("consRadioNo");

                            let travelRadioYes: any = document.getElementById("travelRadioYes");
                            let travelRadioNo: any = document.getElementById("travelRadioNo");

                            if (this.userInformation.professional.free_consultations) {
                                consRadioYes.checked = true;
                                consRadioNo.checked = false;
                            } else {
                                consRadioYes.checked = false;
                                consRadioNo.checked = true;
                            }

                            if (this.userInformation.professional.willing_to_travel) {
                                travelRadioYes.checked = true;
                                travelRadioNo.checked = false;
                            } else {
                                travelRadioNo.checked = true;
                                travelRadioYes.checked = false;
                            }
                        }
                    }, 1000)
                }
            })
    }

    toggleRadioFilter(formName, value) {
        if (formName == 'provider') {
            this.form2.provider = value;
            this.form2.business = 0;
            this.form2.company_name = '';
        } else {
            this.form2.provider = 0;
            this.form2.business = value;
            this.form.first_name = '';
            this.form.last_name = '';
        }
    }

    getProviderType() {
        let uri = '/admin/listTaxonomies'
        let obj = {
            type: 'provider_type'
        }

        this.apiHelper.get(uri, obj, true).subscribe(res => {
            if (res) {
                this.providerList = res.data;
            }
        })
    }

    getAreas() {
        let uri = '/admin/listTaxonomies'
        let obj = {
            type: 'area_of_difficulty'
        }

        this.apiHelper.get(uri, obj, true).subscribe(res => {
            if (res) {
                this.areasOF = res.data;
            }
        })
    }

    selectPType(value) {
        this.form2.provider_type = value;
    }

    onSubmit() {
        let url = '/admin/updateUserProfile';

        if (document.querySelectorAll('.error-fields').length == 0) {
            if (this.form.type != 'professional') {
                this.apiHelper.post(url, this.form, true).subscribe(res => {
                    this.userCreated = true;

                    setTimeout(() => {
                        this.userCreated = false;
                    }, 2000);

                    if (this.userPhoto) {
                        this.submitPhoto(res.id);
                    }

                });
            } else {
                // collect checked values
                let areaArray = [];
                let ageArray = [];
                let provider_type = [];

                $('.checkedAof').each((index, elem: any) => {
                    if ($(elem).is(':checked')) {
                        areaArray.push($(elem).val())
                    }
                });

                $('.checkedPType').each((index, elem: any) => {
                    if ($(elem).is(':checked')) {
                        provider_type.push($(elem).val())
                    }
                });

                // collect checked values age
                $('.checkedAge').each((index, elem: any) => {
                    if ($(elem).is(':checked')) {
                        ageArray.push($(elem).val())
                    }
                });

                this.form2['area_of_difficulty'] = areaArray.join();
                this.form2['child_age'] = ageArray.join();
                this.form2['provider_type'] = provider_type.join();
                this.form2['free_consultations'] = this.consultation;
                this.form2['willing_to_travel'] = this.travel;

                let formForSumbitLicence = [];

                let formValueLicence = this.licenceForm.getRawValue();
                formValueLicence = formValueLicence.licenses;


                for (let i = 0; i < formValueLicence.length; i++) {
                    if (formValueLicence[i].license_type != '' || formValueLicence[i].license_number != '' || formValueLicence[i].name_of_licensing != '') {
                        let licenceObj = {
                            license_type: '',
                            license_number: '',
                            name_of_licensing: '',
                        }

                        licenceObj.license_type = formValueLicence[i].license_type;
                        licenceObj.license_number = formValueLicence[i].license_number;
                        licenceObj.name_of_licensing = formValueLicence[i].name_of_licensing;

                        formForSumbitLicence.push(licenceObj);
                    }
                }

                this.form2.licenses = JSON.stringify(formForSumbitLicence);


                let formForSumbitEducation = [];

                let formValueEducation = this.educationForm.getRawValue();
                formValueEducation = formValueEducation.education;
                console.log(formValueEducation)
                for (let i = 0; i < formValueEducation.length; i++) {
                    if (formValueEducation[i].title != '' && formValueEducation[i].from != '' && formValueEducation[i].to != '') {
                        let licenceObj = {
                            title: '',
                            from: '',
                            to: '',
                            description: ''
                        }

                        licenceObj.title = formValueEducation[i].title;
                        licenceObj.from = formValueEducation[i]['from']['date']['year'] + '-' + formValueEducation[i]['from']['date']['month'] + '-' + formValueEducation[i]['from']['date']['day'];
                        licenceObj.to = formValueEducation[i]['to']['date']['year'] + '-' + formValueEducation[i]['to']['date']['month'] + '-' + formValueEducation[i]['to']['date']['day'];
                        licenceObj.description = formValueEducation[i].description;

                        formForSumbitEducation.push(licenceObj);
                    }
                }

                this.form2.education = JSON.stringify(formForSumbitEducation)
                /** Education PART */

                let profForm = Object.assign(this.form2, this.form);

                this.apiHelper.post(url, profForm, true).subscribe(res => {
                    this.userCreated = true;

                    // reset
                    areaArray = [];
                    ageArray = [];
                    provider_type = [];

                    setTimeout(() => {
                        this.userCreated = false;
                    }, 1500);

                    if (this.userPhoto) {
                        this.submitPhoto(res.id);
                    }

                });
            }
        } else {
            alert('Please enter required fields.')
        }
    }

    addPhoto(event) {
        let target = event.target || event.srcElement;
        let photos = target.files;

        const reader = new FileReader();
        const image = this.element.nativeElement.querySelector('.avatar');

        reader.onload = (e: any) => {
            let src = e.target.result;
            image.src = src;
            this.userPhoto = e.target.result;
            console.log(this.userPhoto)
        };

        setTimeout(function () {
            reader.readAsDataURL(target.files[0]);
        }, 1000)
    }

    // call this after user is created
    submitPhoto(userId) {
        let url = '/admin/uploadProfilePhoto';

        let imgObj = {
            user_id: this.form.user_id,
            image_base64: this.userPhoto
        }

        this.apiHelper.post(url, imgObj, true).subscribe(res => {
            console.log(res)
        })
    }
}
