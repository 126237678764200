import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'dateText'})

export class dateText implements PipeTransform {
  transform(value: string): string {
    const months = [
      {
        text: 'January',
        value :'01'
      }, {
        text: 'February',
        value :'02'
      }, {
        text: 'March ',
        value :'03'
      }, {
        text: 'April',
        value: '04'
      }, {
        text: 'May',
        value: '05'
      }, {
        text: 'June',
        value: '06'
      }, {
        text: 'July',
        value: '07'
      }, {
        text:'August',
        value: '08'
      }, {
      text: 'September',
      value: '09'
      },{
        text: 'October',
        value: '10'
      }, {
        text:'November', 
        value: '11'
      }, {
        text:'December', 
        value:'12'
      }
    ];
    let month = value.split('-')[1];
    let year = value.split('-')[0];
    let day = value.split('-')[2].split(' ')[0];
    let date = '';
    for (let i = 0; i < months.length; i++) {
      if (month == months[i].value) {
        date =day+' '+months[i].text + ' ' + year;
      }
    }
    
    return date
  }
}