import { Component, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import * as Constants from '../../../CONSTANTS';
import { ApiHelperService } from '../../shared/api-helper.service';
import { FeedService } from './feed.service';

@Component({
  selector: 'feed',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./feed.scss'],
  templateUrl: './feed.html',
  providers: [FeedService]
})
export class Feed {

  public feed:Array<Object>;
  public members:any[];
  public  base_url = Constants.URL_API;

  constructor(
    private _feedService:FeedService,
    private api: ApiHelperService
  ) {}

  ngOnInit() {
    this._loadFeed();
    this._getMembers();
  }

  expandMessage (message){
    message.expanded = !message.expanded;
  }

  private _loadFeed() {
    this.feed = this._feedService.getData();
  }

  private _getMembers() {
    let uri = '/admin/listMembership';

    let obj = {
      order: 'desc'
    }

    this.api.get(uri, obj, true).subscribe(res => {
      this.members = res.data;
    });
  }
}
