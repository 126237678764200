import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import * as Constants from '../../CONSTANTS';

@Injectable()
export class SharedService {

  userDataLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  // this one contains image
  userDataInformation: BehaviorSubject<boolean> = new BehaviorSubject(false);

  showLoginError: BehaviorSubject<boolean> = new BehaviorSubject(false);

  user_id:number;
  private base_url = Constants.URL_API;

  private container = {
    conversations: [],
    confirmed_contacts: [],
    pending_contacts: [],
    requested_contacts: [],
    user: {},
    userDetails: {},
    chatType: {},
    enquire: [],
    new_messages: {},
    globalStats: {}
  }

  constructor(
    private router: Router
  ) {}
    
  replaceData(data: any, aName: string) {
    this.container[aName] = data;
  }

  public returnWebsiteUrl() {
    //return 'http://childnexus.test.com';
      return 'https://childnexus.com';
  }
    
  getData(name: string): any {
    return this.container[name];
  }

  emptyAll() {
    this.container.conversations = [],
    this.container.user = {},
    this.container.userDetails = {}
  }

  returnUrl() {
    return this.base_url;
  }
}