import {Component, ElementRef, ViewEncapsulation, AfterContentInit, EventEmitter} from '@angular/core';

import {BlogService} from './';
import {ApiHelperService} from '../shared/api-helper.service'
import swal from 'sweetalert2'

@Component({
    selector: 'blog',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./blog.component.scss'],
    templateUrl: './blog.component.html',
    providers: [BlogService]
})

export class BlogComponent {
    activeShown = true;
    inactiveShown = false;

    constructor(
        private blogService: BlogService,
        private api: ApiHelperService
    ) {
        this.blogService.getPostList();
    }

    public blogPosts;
    private filter: any = {};
    public allAOFs;
    public numberOfArticles = null;
    public currentPage = 1;
    public showActiveModal = false;
    public globalStats;
    public p: number = 1;

    ngOnInit() {
        this.loadArtcles();
        this._getAllStats();
        this.getAofs();
    }

    _getAllStats() {
        let uri = "/admin/getGlobalStats";

        this.api.get(uri, {}, true).subscribe(res => {
            this.globalStats = res[0];
        })
    }

    loadArtcles() {
        this.blogService.postLoaded.subscribe(loaded => {
            if (loaded) {

                var array = $.map(this.blogService.blogPosts['data'], function (value, index) {
                    if (typeof value == 'object') {
                        return [value];
                    }
                });

                this.numberOfArticles = this.blogService.blogPosts['total'];

                setTimeout(() => {
                    if (this.blogService.blogPosts['data'] && this.blogService.blogPosts['data']['inActiveCount']) {
                        this.globalStats.inactive = this.blogService.blogPosts['data']['inActiveCount'];
                        this.attachEvents();
                    }
                }, 1000)

                this.blogPosts = array;
            }
        });
    }

    attachEvents() {
        var globalSelf = this;

        //      $('.single-checkbox').on('change', function() {
        //        if ($(this).prop('checked') == true) {
        //            console.log('checked');
        //          globalSelf.checkedArticles.push($(this).val())
        //        } else {
        //          for (let i = 0; i < globalSelf.checkedArticles.length; i++) {
        //            if (globalSelf.checkedArticles[i] == $(this).val()) {
        //              var index = globalSelf.checkedArticles.indexOf(globalSelf.checkedArticles[i]);
        //              globalSelf.checkedArticles.splice(index, 1);
        //            }
        //          }
        //        }
        //      })
    }

    changeOnClick(e: any) {
        if (e.checked == true) {
            this.checkedArticles.push(e.id.toString())
        } else {
            for (let i = 0; i < this.checkedArticles.length; i++) {
                if (this.checkedArticles[i] == e.id.toString()) {
                    var index = this.checkedArticles.indexOf(this.checkedArticles[i]);
                    this.checkedArticles.splice(index, 1);
                    break;
                }
            }
        }
    }

    ngAfterViewInit() {
      // var globalSelf = this;
      
      setTimeout( () => {
        this.attachEvents();
      }, 1500)
    }

pageChange(page) {
    this.blogService.getPostList(page);
    this.currentPage = page;

    setTimeout(() => {
        this.attachEvents();
    }, 2000)
}

getAofs() {
    let uri = '/admin/listTaxonomies';

    let aofFilter: any = {};
    aofFilter.type = 'area_of_difficulty';

    this.api.get(uri, aofFilter, true)
        .subscribe(res => {
            if (res) {
                this.allAOFs = res.data;
            }
        })
}

// FILTERS
// AOF
filterAofs(value) {
    this.filter.area_of_difficulty = value;
    this.blogService.getPostList(this.currentPage, this.filter);

    setTimeout(() => {
        this.attachEvents();
    }, 2000)
}

toggleActive(value) {
    this.filter.active = value;
    if (this.filter['new']) {
        delete this.filter['new']
    }
    if (this.filter['featured']) {
        delete this.filter['featured']
    }

    this.blogService.getPostList(this.currentPage, this.filter);

    setTimeout(() => {
        this.attachEvents();
    }, 1500)
}

toggleFilter(name, value) {
    this.filter[name] = value;

    if (this.filter['active']) {
        delete this.filter['active']
    }

    this.blogService.getPostList(this.currentPage, this.filter);

    setTimeout(() => {
        this.attachEvents();
    }, 1500)
}

sort(value) {
    this.filter.order = value;
    this.blogService.getPostList(this.currentPage, this.filter);
}

searchBlogs(event) {
    let uri = '/admin/listArticles';

    this.filter.keyword = event;
    this.blogService.getPostList(this.currentPage, this.filter);

    setTimeout(() => {
        this.attachEvents();
    }, 1000)

}

resetFilter() {
    this.blogService.getPostList(this.currentPage, {});
}

toggleStatus() {
    this.activeShown = !this.activeShown;
    this.inactiveShown = !this.inactiveShown;

    if (this.activeShown) {
        this.filter.active = 1;
    } else {
        this.filter.active = 0;
    }

    this.blogService.getPostList(this.filter);
}

    public checkedArticles: any = [];

selectAll(value) {
    var self = this;
    if (value == 1) {
        $('.single-checkbox').each(function (index, elem) {
            $(elem).prop('checked', true);
            self.checkedArticles.push($(elem).val())
        });
    } else {
        $('.single-checkbox').each(function (index, elem) {
            $(elem).prop('checked', false);
        });
        self.checkedArticles = [];
    }
}

activateArticles(event) {
    let uri = '/admin/publishArticle';

    if (this.checkedArticles.length) {
        let obj = {
            article_id: this.checkedArticles
        }

        this.api.post(uri, obj, true).subscribe(res => {
            this.checkedArticles = [];
            $('.single-checkbox').each(function (index, elem) {
                $(elem).prop('checked', false);
            });
            this.blogService.getPostList();
            this.loadArtcles();

            swal.fire({
                text: 'Articles Activated! ',
                icon: 'success',
                timer: 1500,
            });
        })
    }
}


deleteArticle() {
    let uri = '/admin/deleteArticle';

    if (this.checkedArticles.length) {

        let obj = {
            article_id: this.checkedArticles
        }

        //        for (let i = 0; i < this.checkedArticles.length; i++) {
        //          for (let x = 0; x < this.blogPosts.length; x++) {
        //            if (this.checkedArticles[i] == this.blogPosts[x].id) {
        //              console.log(x, i)
        //              this.blogPosts.splice(this.blogPosts[x], 1); 
        //            }
        //          }
        //        }  

        this.api.post(uri, obj, true).subscribe(res => {
            this.blogService.getPostList();
            this.loadArtcles();
            this.checkedArticles = [];
            $('.single-checkbox').each(function (index, elem) {
                $(elem).prop('checked', false);
            });

        })
    }
}



showPromptModal(event) {
    if (this.checkedArticles.length) {
        swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure want to delete ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                this.deleteArticle();
                swal.fire({
                    title: 'Deleted!',
                    text: 'Articles Deleted!',
                    icon: 'success'
                });
            }
        });
    } else {
        swal.fire({
            text: 'Select one or more articles to delete ',
            icon: 'info',
        });
    }
}


featuredArticles(event) {
    let uri = '/admin/setFeatured';

    if (this.checkedArticles.length) {
        let obj = {
            article_id: this.checkedArticles
        }

        this.api.post(uri, obj, true).subscribe(res => {
            //console.log(res)
            this.checkedArticles = [];
            $('.single-checkbox').each(function (index, elem) {
                $(elem).prop('checked', false);
            });
            this.blogService.getPostList();
            this.loadArtcles();



            swal.fire({
                text: 'Articles Featured! ',
                icon: 'success',
                timer: 1500,
            });
        });
    }
}
}