import { Component, Input, Output } from '@angular/core';


@Component({
  selector: 'profile-card',
  templateUrl: 'profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
})

export class ProfileCardComponent {
    constructor() {}

    @Input() data;
    @Input() baseUrl;

    public imageUrl;
    public lastActive;
    public showMessageModal;

    ngOnInit() {
      this.imageUrl = this.baseUrl + '/' + this.data.user.profile_image; 
      this.lastActive = this.convertActivity(this.data.user.last_activity);
    }

    showMsgModal() {
      console.log('show')
      this.showMessageModal = true;
    }

    sendMsg(message:string) {
      this.showMessageModal = false;
    }

    convertActivity(date) {
      let today = new Date();
      let createdOn = new Date(date);
      let msInDay = 24 * 60 * 60 * 1000;

      createdOn.setHours(0,0,0,0);
      today.setHours(0,0,0,0)

      var diff = (+today - +createdOn)/msInDay
      diff = Math.ceil(diff / 24)
      
      return diff
    }
    
    getMembershipDetails(data:string){
        
    }
}