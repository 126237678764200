import {
	Component,
	ViewEncapsulation,
	OnDestroy
} from '@angular/core';

import {
	Router,
	ActivatedRoute
}
from '@angular/router';

import {HeaderComponent} from './theme/header'

@Component({
    selector: 'admin',
	  encapsulation: ViewEncapsulation.None,
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent {
	constructor(
		public router: Router,
		private route: ActivatedRoute
	) {
		window.onbeforeunload = function(e) {
			// localStorage.clear();
		};
	}

	public sidebarState:boolean = true;

	toggleNavigation(e) {
		if (e.target.className == 'fa fa-bars') {
			this.sidebarState = !this.sidebarState;
		}
	}

	onDestroy() {
		localStorage.clear();
	}
	
}