import {Component, ElementRef, ViewEncapsulation, OnInit, OnDestroy, } from '@angular/core';
import 'rxjs/add/operator/map';
import { Subject, BehaviorSubject,Observable } from 'rxjs';

import { ApiHelperService } from '../shared';
import { SharedService } from '../shared';
import * as Constants from '../../CONSTANTS';

@Component({
  selector: 'profile',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./profile.component.scss'],
  templateUrl: './profile.component.html'
})

export class ProfileComponent {
    constructor(
      private api: ApiHelperService,
      private shared: SharedService,
      private element: ElementRef
    ) {
    }

    private user;
    public photo = 'assets/default.png';
    public userCreated;
    private base_url = Constants.URL_API;

    public loadedImage = false;

    public form: any = {
			user_id: "", type: "admin", first_name: "", last_name: "", city: "", 
			state: "", zipcode: "", address1: "", address2: "", address3: "", 
			phone1: "", phone2: "", email: ""
	  };

    ngOnInit() {
      this.getUserInformations(localStorage.getItem('user_id'));
    }

    getUserInformations(id) {
      let uri = '/admin/getUser/' + id;

      this.api.get(uri, {}, true)
        .subscribe(res => {
          if (res) {
            // populate form with data
            this.form.user_id = id;
            this.form.type = res.type;
            this.form.first_name = res.first_name;
            this.form.last_name = res.last_name;
            this.form.city = res.city;
            this.form.state = res.state;
            this.form.zipcode = res.zipcode;
            this.form.adress1 = res.address1;
            this.form.adress2 = res.address2;
            this.form.adress3 = res.address3;
            this.form.phone1 = res.phone1;
            this.form.phone2 = res.phone2;
            this.form.email = res.email;
            this.form.phone1 = res.phone1;
            if (res.images.length) {
              this.photo = this.base_url + '/' + res.images[0].img;
            }
          }
        })
    }

    updateUser() {
      console.log(this.form)
      let url = '/admin/updateUserProfile';
      this.api.post(url, this.form, true).subscribe(res => {
        console.log(res)
        this.userCreated = true;
          
          setTimeout(() => {
            this.userCreated = false;
          }, 1500);
      });
    }

    ngOnDestroy() {
      // console.log('destroy')
    }

    photoChoosed(event){
      let photos = event.target.files;
    
      const reader = new FileReader();
      const image = this.element.nativeElement.querySelector('.avatar');

      reader.onload = (e: any) => {
        let src = e.target.result;
        image.src = src;
        this.photo = src;
      };
      reader.readAsDataURL(event.target.files[0]);
      this.loadedImage = true;
    }

    public uploadImage() {
      let uri = '/admin/uploadProfilePhoto';

      let imgObj = {
        user_id: localStorage.getItem('user_id'),
        image_base64: this.photo
      }

      this.api.post(uri, imgObj, true).subscribe(res => {
        console.log(res)
      })
    }
}