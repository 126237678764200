import {Component, ElementRef, ViewEncapsulation} from '@angular/core';

import { ApiHelperService } from '../shared/api-helper.service'

@Component({
  selector: 'user',
  // encapsulation: ViewEncapsulation.None,
  styleUrls: ['./user.component.scss'],
  templateUrl: './user.component.html',
  encapsulation: ViewEncapsulation.None
})

export class UserComponent {
  constructor(
    private apiHelper: ApiHelperService
  ) {
    this._getAllUsers(this.currentPage, this.filter);
    this._getAllStats();
  }

  public users: any;
  private filter:any = {};
  public showDeleteModal;
  public userDeleted = false;
  public currentUser = localStorage.getItem('user_id');
  public numberOfUsers;
  public globalStats;
  public currentPage = 1;
  public perPage = 25;
  public p: number = 1;
  // id to delete user
  private storeId;

  _getAllStats() {
    let uri = "/admin/getGlobalStats";

    this.apiHelper.get(uri, {}, true).subscribe(res => {
      this.globalStats = res[0];
    })
  }

  public _getAllUsers(page, filter) {
    let uri = '/admin/listUsers';
    let params:any = {};

    if (!filter) {
      params = {}
    } else {
      params = filter;
    }

    if (page) {
      params.page = page;
    } else {
      params.page = this.currentPage;
    }

    this.apiHelper.get(uri, params, true)
      .subscribe(res => {
      if (res) {
        this.users = res.data;
        this.numberOfUsers = res.total;
      }
    });
  }

  public filterUsers(type, value) {
    this.filter[type] = value;

    this._getAllUsers(this.currentPage, this.filter);
  }

  public searchUsers(event) {
    this.filter.keyword = event;

    this._getAllUsers(this.currentPage, this.filter);
  }

  public showPerPage(value) {
    this.filter.per_page = value;

    this.perPage = value;

    this._getAllUsers(this.currentPage, this.filter);
  }

   prompt(id) {
     this.showDeleteModal = true;
     this.storeId = id;
   }

   confirmDelete(action) {
    if (action == 'yes') {
      this.deleteUser(this.storeId);
    } else {
      this.storeId = null;
      this.showDeleteModal = false;
    }
   }

   deleteUser(id) {
    let uri = '/admin/deleteUser';

    let obj = {
      user_id : id
    }

    this.apiHelper.post(uri, obj, true)
    .subscribe(res => {
      if (res) {
        this._getAllUsers(this.currentPage, this.filter);

        this.userDeleted = true;
        this.showDeleteModal = false;
        this.storeId = null;

        setTimeout(() => {
          this.userDeleted = false;
        }, 2000)
      }
    })   
   }

   resetPassword(id) {
     let uri = '/resetPassword/' + id

     this.apiHelper.post(uri, id, true).subscribe(res => {
       if (res) {
         alert('User Deleted')
       }
     });
   }

  pageChange(page) {
    this.currentPage = page;
    console.log('current: ' + this.currentPage)
    this._getAllUsers(page, this.filter);
  }

}
