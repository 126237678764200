import {Component, Input, ElementRef, HostListener, ViewEncapsulation, OnChanges} from '@angular/core';
import { AuthService } from '../../pages/shared/auth.service';
import { SharedService } from '../../pages/shared/shared.service';
import { ApiHelperService } from '../../pages/shared/api-helper.service';


@Component({
  selector: 'sidebar',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./sidebar.component.scss'],
  templateUrl: './sidebar.component.html'
})

export class SidebarComponent {
  
  constructor(
    private _elementRef:ElementRef,
    private shared: SharedService,
    private auth: AuthService
  ) {}

  public user;
  public image;

  @Input() sidebarActive:boolean;
  public activeStateSidebar;

  ngOnInit() {
    if (localStorage.getItem('auth_token')) {
      this.shared.userDataInformation.subscribe(loaded => {
        if (loaded) {
          this.user = this.shared.getData('userDetails');
          
          if (this.user.images && this.user.images.length) {
            this.image = this.shared.returnUrl() + '/' + this.user.images[0].img;
          } else {
            this.image = 'assets/default.png';
          }
        }
      });
    }
  }

  ngOnChanges() {
    this.activeStateSidebar = this.sidebarActive;
  }

  logoutUser() {
    this.auth.logout();
  }
  
}
