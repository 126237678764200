import {Component, ElementRef, ViewEncapsulation} from '@angular/core';

import {ProfileCardComponent} from './';

import {ApiHelperService} from '../shared/api-helper.service';
import {SharedService} from '../shared/shared.service';
//import {setTimeout} from 'timers';
import * as $ from 'jquery';


@Component({
    selector: 'membership',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./membership.component.scss'],
    templateUrl: './membership.component.html'
})

export class MembershipComponent {
    public filter: any = {};

    constructor(
        private apiHelper: ApiHelperService,
        private shared: SharedService
    ) {
        this._getMembers(null);
        this._getAofs();
        this._getAllStats();
        this._getProviderType();
    }

    public members;
    public base_url = this.shared.returnUrl();
    public allAOFs;
    public allCities;
    public numberOfUsers;
    public globalStats;
    public providerList;
    public p: number = 1;
    public checkedMembers: any = [];

    private _getMembers(filter) {
        let uri = '/admin/listMembership';
        let obj;

        if (!filter) {
            obj = null
        } else {
            obj = filter;
        }
        this.apiHelper.get(uri, obj, true).subscribe(res => {
           
            this.members = res.data;
            this.numberOfUsers = res.total;

            setTimeout(() => {
                this.checkboxToggle();
            }, 500)

        });
    }

    _getAofs() {
        let uri = '/admin/listTaxonomies';

        let aofFilter: any = {};
        aofFilter.type = 'area_of_difficulty';

        this.apiHelper.get(uri, aofFilter, true)
            .subscribe(res => {
                if (res) {
                    this.allAOFs = res.data;
                }
            })

        let cityFilter: any = {};
        cityFilter.type = 'city';

        this.apiHelper.get(uri, cityFilter, true)
            .subscribe(res => {
                if (res) {
                    this.allCities = res.data;
                }
            })
    }

    _getProviderType() {
        let uri = '/admin/listTaxonomies'
        let obj = {
            type: 'provider_type'
        }

        this.apiHelper.get(uri, obj, true).subscribe(res => {
            if (res) {
                this.providerList = res.data;
            }
        })
    }

    _getAllStats() {
        let uri = "/admin/getGlobalStats";

        this.apiHelper.get(uri, {}, true).subscribe(res => {
            this.globalStats = res[0];
        })
    }

    filterAofs(value) {
        this.filter.area_of_difficulty = value;

        this._getMembers(this.filter);
    }

    filterLocation(value) {
        this.filter.location = value;
        this._getMembers(this.filter);
    }

    filterType(value) {
        this.filter.provider_type = value;

        this._getMembers(this.filter);
    }

    filterMemberType(value) {
        this.filter.membership_type = value;

        this._getMembers(this.filter);
    }

    toggleRadioFilter(filterName, filterValue) {
        let currentFilters = ['new', 'expiring', 'featured', 'status'];

        for (let i = 0; i < currentFilters.length; i++) {
            if (currentFilters[i] != filterName) {
                delete this.filter[currentFilters[i]];
            }
        }

        this.filter[filterName] = filterValue;

        this._getMembers(this.filter);
    }

    sort(value) {
        this.filter.order = value;
        this._getMembers(this.filter);
    }

    searchMembers(event) {
        this.filter.keyword = event;

        this._getMembers(this.filter)
    }

    resetFilter() {
        this.filter = {};

        this._getMembers(this.filter);
    }

    pageChange(page) {
        this.filter.page = page;
        this.p = page;
        this._getMembers(this.filter);
    }

    // BULK ACTIONS
    ngAfterViewInit() {
        var globalSelf = this;

        setTimeout(() => {
            $('.single-checkbox').on('change', function () {
                if ($(this).prop('checked') == true) {
                    globalSelf.checkedMembers.push($(this).val())
                } else {
                    for (let i = 0; i < globalSelf.checkedMembers.length; i++) {
                        if (globalSelf.checkedMembers[i] == $(this).val()) {
                            var index = globalSelf.checkedMembers.indexOf(globalSelf.checkedMembers[i]);
                            globalSelf.checkedMembers.splice(index, 1);
                        }
                    }
                }
            })
        }, 1000)
    }

    checkboxToggle() {
        var globalSelf = this;

        $('.single-checkbox').on('change', function () {
            if ($(this).prop('checked') == true) {
                globalSelf.checkedMembers.push($(this).val())
            } else {
                for (let i = 0; i < globalSelf.checkedMembers.length; i++) {
                    if (globalSelf.checkedMembers[i] == $(this).val()) {
                        var index = globalSelf.checkedMembers.indexOf(globalSelf.checkedMembers[i]);
                        globalSelf.checkedMembers.splice(index, 1);
                    }
                }
            }
        })
    }

    public succesModal;

    changeStatus(value) {
        let uri;
        let status;
        let obj: any;

        switch (value) {
            case 0:
                uri = '/admin/activateMembership';
                status = 2;
                break;
            case 1:
                uri = '/admin/deActivateMembership';
                status = 1;
                break;
            case 2:
                uri = '/admin/deleteMembership';
                break;
            case 3:
                uri = '/admin/upgradeMembership';
                break;
            case 4:
                uri = '/admin/setSponsoredMembership';
        }

        if (this.checkedMembers.length) {
            obj = {
                ids: this.checkedMembers
            }

            if (status) {
                obj.status = status;
            }

            this.apiHelper.post(uri, obj, true).subscribe(res => {
                this.succesModal = true;
                if (uri == '/admin/deleteMembership') {
                    this._getMembers(this.filter);
                }
                setTimeout(() => {
                    this.succesModal = false;
                }, 1500)
            })
        }
    }

    selectAll(value) {
        if (value == 1) {
            var self = this;

            $('.single-checkbox').each(function (index, elem) {
                $(elem).prop('checked', true);
                self.checkedMembers.push($(elem).val())
            });
        } else {
            $('.single-checkbox').each(function (index, elem) {
                $(elem).prop('checked', false);
                self.checkedMembers = [];
            });
        }
    }
}