import {Component, ElementRef} from '@angular/core';

// import { AuthService } from '../../pages/shared/auth.service';
import { SharedService } from '../../../pages/shared/shared.service';
import { ApiHelperService } from '../../../pages/shared/api-helper.service';

@Component({
  selector: 'add-tax',
  styleUrls: ['./add-tax.component.scss'],
  templateUrl: './add-tax.component.html'
})

export class AddTaxonomyComponent {
  public color:string= '#2889e9';
  
  constructor(
    private shared: SharedService,
    private api: ApiHelperService,
    private element: ElementRef
  ) {}

  public taxonomyList:any = [];
  public imageOne;
  public imageTwo;
  public taxonomyCreated = false;
  public selectedColor = '';
 public selected: boolean=true;

  ngOnInit() {
    console.log('taxonomy')
  }

  onChangeColor(event) {
    this.selectedColor = event;
  }

  onChangeType(value) {
    console.log(value)
    this.form.taxonomy_type = value;
  }

  public form: any = {
		name: "", description: "", image1: "", image2: "", 
	  taxonomy_type: 1
	};
  
  submitTaxonomy() {
    let uri = '/admin/addTaxonomy';

    this.form.color = this.selectedColor;

    this.api.post(uri, this.form, true).subscribe(res => {
      if (res) {
        this.taxonomyCreated = true;

        setTimeout(() => {
          this.taxonomyCreated = false;
        }, 2000);
      }
    })
  }

  addPhoto(event) {
    let target = event.target || event.srcElement;
    let photos = target.files;

    const reader = new FileReader();
    const image = this.element.nativeElement.querySelector('#img-upload');

    reader.onload = (e: any) => {
        let src = e.target.result;
        image.src = src;
        this.form.image1 = src;
    };

    setTimeout(function() {
      reader.readAsDataURL(target.files[0]);
    }, 1000) 
  }

  addPhotoTwo(event) {
    let target = event.target || event.srcElement;
    let photos = target.files;

    const reader = new FileReader();
    const image = this.element.nativeElement.querySelector('#img-upload-two');

    reader.onload = (e: any) => {
        let src = e.target.result;
        image.src = src;
        this.form.image2 = src;
    };

    setTimeout(function() {
      reader.readAsDataURL(target.files[0]);
    }, 1000) 
  }
}
