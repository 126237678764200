import { Injectable, EventEmitter } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { ApiHelperService } from './api-helper.service';
import { SharedService } from './shared.service';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthService {

  private loggedIn = false;

  constructor(
    private http: HttpClient,
    private apiHelper: ApiHelperService,
    private router: Router,
    private shared: SharedService
  ) {
    if (localStorage.getItem('auth_token')) {
      this.getUserInformations(localStorage.getItem('user_id'));
      // this.router.navigateByUrl('dashboard');
    } else {
      // console.log('not logged')
    }
  }

  registerUser(data) {
    let uri = '/register';

    this.apiHelper.post(uri, data, false)
      .subscribe(res => {
        if (res) {
          this.loginUser(res, res.email, data.password);
        }
      })
   }

  /*
   * LOGIN
   */
  //  this is used after register
  loginUser(res, email, password) {

    let options = Object.assign({}, res);

    options.grant_type = 'password';
    options.username = email;
    options.password = password;

    let uri = '/login';

    this.apiHelper.post(uri, options)
      .subscribe(res => {
        if (res.api_token) {
          localStorage.setItem('auth_token', res.api_token);
          localStorage.setItem('user_id', res.id);
          this.loggedIn = true;
          this.shared.replaceData(res, 'user');
          this.router.navigateByUrl('dashboard');

          this.getUserInformations(localStorage.getItem('user_id'));
        }
      });
  }


  // Get user details
  submitLoginData(option) {
    let uri = '/login';

    this.apiHelper.post(uri, option, false)
      .subscribe(res => {
        if (res.api_token) {
            localStorage.setItem('auth_token', res.api_token);
            localStorage.setItem('user_id', res.id);
            this.loggedIn = true;
            this.shared.replaceData(res, 'user');
            this.shared.userDataLoaded.next(true);
            this.router.navigateByUrl('dashboard');

            this.getUserInformations(localStorage.getItem('user_id'));

            this.shared.showLoginError.next(false);
        } else {
          this.shared.showLoginError.next(true);
        }
      });
  }

  getUserInformations(id) {
    let uri = '/admin/getUser/' + id;

    this.apiHelper.get(uri, {}, true)
      .subscribe(res => {
        if (res) {
          this.shared.replaceData(res, 'userDetails');
          this.shared.userDataInformation.next(true);
        } else {
          this.router.navigateByUrl['/login'];
          localStorage.removeItem('auth_token');
        }
      })
  }
  
  logout() {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('user_id');
    this.router.navigateByUrl('login');
    this.loggedIn = false;
    this.shared.emptyAll();
  }

  isLoggedIn() {
    let token = localStorage.getItem('auth_token');
    
    if (token) {
      this.loggedIn = true;
      return true;
    }

    return this.loggedIn;
  }
}