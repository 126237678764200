import {Component, ElementRef, ViewEncapsulation} from '@angular/core';

import {ApiHelperService} from './../shared/api-helper.service';
import {SharedService} from './../shared/shared.service';

import {IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';

@Component({
    selector: 'consultant',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./consultant.component.scss'],
    templateUrl: './consultant.component.html',
    host: {
        '(document:click)': 'closeDropdown($event)',
    },
})

export class ConsultantComponent {
    constructor(
        private api: ApiHelperService,
        private shared: SharedService
    ) {}

    public allCons;
    public allCities;
    public showMessageModal = null;
    public globalStats;
    public numberOfCons;

    public dropdownStatus;
    public selectedDropdown;

    public fromDate;
    public toDate;
    public p: number = 1;

    public noteValue;

    private filter: any = {};

    myDatePickerOptions: IAngularMyDpOptions = {
        dateRange: false,
        dateFormat: 'yyyy-mm-dd'
        // other options are here...
    };


    model: IMyDateModel = null;
    model2: IMyDateModel = null;

    public triggerDropdown(i) {
        this.dropdownStatus = true;
        if (this.selectedDropdown == i) {
            this.selectedDropdown = null
        } else {
            this.selectedDropdown = i;
        }
    }

    public setConsultation(id, status) {
        // admin/setConultationStatus - saljes POST I parametere - consultation_id I status
        let uri = '/admin/setConultationStatus';

        let obj = {
            consultation_id: id,
            status: status
        }

        this.api.post(uri, obj, true)
            .subscribe(res => {
                this.dropdownStatus = false;
                this._getConsultations(this.filter);
            })
    }

    showMsgModal(i) {
        this.showMessageModal = i;
        this.noteValue = this.allCons[i].message;
    }

    closeDropdown(event) {
        let target = event.srcElement || event.target;
        if (target.className != 'dropdown-status'
            && this.dropdownStatus == true
            && target.className != 'btn btn-success'
            && target.className != 'btn btn-danger'
            && target.className != 'btn btn-info') {
            this.dropdownStatus = false;
        }
    }

    sendMsg(id) {
        this.showMessageModal = -1;
        if (id) {
            this.saveNote(id);
            this._getConsultations(this.filter)
        }
    }

    ngOnInit() {
        this._getConsultations(this.filter);
        this._getAofs();
        this.getAllStats();
        this.model = {isRange: false, singleDate: {jsDate: new Date()}};
        this.model2 = {isRange: false, singleDate: {jsDate: new Date()}};
    }

    pageChange(page) {
        this.filter.page = page;
        this._getConsultations(this.filter);
    }

    _buildImageUrl(imagePath) {
        return this.shared.returnUrl() + '/' + imagePath
    }

    _getConsultations(filter) {
        let uri = '/admin/listConsultations';

        if (!filter) {
            filter = {}
        }

        this.api.get(uri, filter, true)
            .subscribe(res => {
                if (res) {
                    this.allCons = res.data;
                    this.numberOfCons = res.total;
                    console.log(this.allCons)
                }
            });
    }

    public runReports() {
        this.filter.fromDate = this.fromDate;
        this.filter.toDate = this.toDate;

        this._getConsultations(this.filter);
    }

    private _getAofs() {
        let uri = '/admin/listTaxonomies';

        let cityFilter: any = {};
        cityFilter.type = 'city';

        this.api.get(uri, cityFilter, true)
            .subscribe(res => {
                if (res) {
                    this.allCities = res.data;
                }
            })
    }

    public filterCons(type, value) {
        this.filter[type] = value;

        this._getConsultations(this.filter);
    }

    public searcCons(event) {
        this.filter.keyword = event;

        this._getConsultations(this.filter);
    }

    public resetFilter() {
        this.filter = {};

        this._getConsultations(this.filter);

        // not the best way
        $("#consultation-filter").val("default");
        $("#cons-user-filter").val("default");
        $("#cons-user-location-filter").val("default");
    }

    getAllStats() {
        let uri = "/admin/getGlobalStats";

        this.api.get(uri, {}, true).subscribe(res => {
            console.log(res)
            this.globalStats = res[0];
        })
    }

    onDateChanged(event: IMyDateModel) {
        // event properties are: event.date, event.jsdate, event.formatted and event.epoc
        this.fromDate = event.singleDate.formatted;
    }

    onEndDateChanged(event: IMyDateModel) {
        // event properties are: event.date, event.jsdate, event.formatted and event.epoc
        this.toDate = event.singleDate.formatted;
    }

    public saveNote(id) {
        let uri = '/admin/addConsultationNote';
        let obj = {
            consultation_id: id,
            note: this.noteValue
        }

        this.api.post(uri, obj, true)
            .subscribe(res => {
                this.noteValue = '';
            })
    }
}