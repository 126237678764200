import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators/map';
import {retry} from 'rxjs/operators/retry';
//import { pipe } from 'rxjs/operators';
import {Router} from '@angular/router';
import * as Constants from '../../CONSTANTS';

@Injectable()
export class ApiHelperService {

    constructor(
        private http: HttpClient,
        private router: Router
    ) {
    }

    //private base_url = Constants.URL_API;
    private base_url = Constants.URL_API;

    private serializeObj(obj) {
        var result = [];
        for (var property in obj)
            result.push(encodeURIComponent(property) + "=" + encodeURIComponent(obj[property]));

        return result.join("&");
    }

    post(url: string, body?: Object, token?: boolean): Observable<any> {
        //let bodyString = JSON.stringify(body);
        let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'});

        // if (token)
        // headers.append('access-token', localStorage.getItem('auth_token'));


        body = this.serializeObj(body);

        let finalUrl;

        if (token) {
            finalUrl = this.base_url + url + '?access-token=' + localStorage.getItem('auth_token');
        } else {
            finalUrl = this.base_url + url;
        }

        return this.http.post(finalUrl, body,
            {
                headers: headers,
                responseType: 'json'
            }).pipe(
                map((res) => res),
                retry(3),
                catchError((error: any) => Observable.throw(error.json().error || 'Server error')));
    }


    get(url: string, query?: object, token?: boolean): Observable<any> {
        let params: HttpParams = new HttpParams();

        for (let k in query) {
            params = params.set(k, query[k]);
        }
        

        let headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'});


        // if (token) {
        //     headers.append('access-token', localStorage.getItem('auth_token'));
        // }
        let finalUrl;

        if (token) {
            finalUrl = this.base_url + url + '?access-token=' + localStorage.getItem('auth_token');
        } else {
            finalUrl = this.base_url + url;
        }
        //map(res => res.json())

        return this.http.get(finalUrl, {
            headers: headers,
            params: params,
            responseType: 'json'
        })
            .pipe(
                map(res => res),
                retry(3),
                catchError((e => {
                    if (e.status === 401) {
                        localStorage.clear();
                        this.router.navigateByUrl('/login');
                        return Observable.throw('Unauthorized');
                    }
                })));
        //            .map(res         => res.json())
        //            .catch((error: any) => Observable.throw(error.json().error || 'Server error'));


    }

    delete(url: string, token?: boolean): Observable<any> {
        let headers = new HttpHeaders();

        let finalUrl;

        if (token) {
            finalUrl = this.base_url + url + '?access-token=' + localStorage.getItem('auth_token');
        } else {
            finalUrl = this.base_url + url;
        }


        return this.http.delete(finalUrl, {headers: headers});
    }
}