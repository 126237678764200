import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { PodcastsComponent } from './podcasts.component';
import { PodcastCardComponent } from './podcast-card/podcast-card.component';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
import { PodcastPreview } from './podcast-preview';
import { RouterModule } from '@angular/router';
import { TagInputModule } from 'ngx-chips';

@NgModule({
  declarations: [
    PodcastsComponent,
    PodcastCardComponent,
    PodcastPreview
  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    RouterModule,
    TagInputModule
  ],
  schemas: [NO_ERRORS_SCHEMA]  // Add this line
})
export class PodcastsModule {}
