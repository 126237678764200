import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from "@angular/router";
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ChartsModule} from 'ng2-charts';
import {TagInputModule} from 'ngx-chips';
import {CustomFormsModule} from 'ng2-validation';
import {ColorPickerModule} from 'ngx-color-picker';
import {NgxPaginationModule} from 'ngx-pagination';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import {dateText} from './pages/shared/date-pipe';
import {AppComponent} from './app.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularEditorModule } from '@kolkov/angular-editor';
//// services
import {ApiHelperService, AuthService, AuthGuard, SharedService} from './pages/shared'

import {
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    CardComponent,
    Feed,
    TileComponent,
    Login,
    Register,
    DashboardComponent,
    ProfileComponent,
    ProfileCardComponent,
    TaxonomyComponent,
    AddTaxonomyComponent,
    EditTaxonomyComponent,
    MembershipComponent,
    AddUserComponent,
    EditUserComponent,
    UserComponent,
    ConsultantComponent,
    EditorComponent,
    BlogCardComponent,
    BlogComponent,
    BlogPreview,
    BarChartComponent,
    StatisticComponent,
    RevenueComponent
} from './';

import {rootRouterConfig} from "./app.routes";
import { PodcastsModule } from './pages/podcasts/podcasts.module';
import { PodcastsService } from './pages/podcasts/podcasts.service';
import { PodcastPostService } from './pages/podcasts/podcast-post/podcast-post.service';
import { PodcastPostComponent } from './pages/podcasts/podcast-post/podcast-post.component';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        CustomFormsModule,
        TagInputModule,
        NgxPaginationModule,
        ColorPickerModule,
        AngularMyDatePickerModule,
        ChartsModule,
        InfiniteScrollModule,
        BrowserAnimationsModule,
        TooltipModule.forRoot(),
        AngularEditorModule,
        PodcastsModule,
        RouterModule.forRoot(rootRouterConfig, {useHash: true})
    ],
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        SidebarComponent,
        CardComponent,
        Feed,
        TileComponent,
        DashboardComponent,
        ProfileComponent,
        AddUserComponent,
        EditUserComponent,
        UserComponent,
        ProfileCardComponent,
        MembershipComponent,
        TaxonomyComponent,
        AddTaxonomyComponent,
        EditTaxonomyComponent,
        ConsultantComponent,
        EditorComponent,
        BlogCardComponent,
        BlogPreview,
        BlogComponent,
        BarChartComponent,
        StatisticComponent,
        RevenueComponent,
        Login,
        Register,
        dateText,
        PodcastPostComponent
    ],
    providers: [
        ApiHelperService,
        AuthService,
        AuthGuard,
        SharedService,
        PodcastsService,
        PodcastPostService
    ],
    bootstrap: [AppComponent]
})

export class AppModule {}