import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject,Observable } from 'rxjs';

import { ApiHelperService } from '../shared/api-helper.service';
import { SharedService } from '../shared/shared.service';

@Injectable()
export class BlogService {

  private userDetails;
  blogPosts;
  singleBlog;
  postLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  singlePostLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
   private apiHelper: ApiHelperService,
   private shared : SharedService
  ) {
    this.shared.userDataLoaded.subscribe(loaded => {
      if (loaded) {
        this.userDetails = this.shared.getData('user');
      }
    })
  }

  returnUser() {
    return this.userDetails;
  }

  getPostList(page?, filter?) {
    let uri = '/admin/listArticles';
    let params = {};
    
    if (page) {
      params = {
        page : page
      }
    }
  
    if (filter) {
      $.extend(params, filter);
    }

    this.apiHelper.get(uri, params, true).subscribe(res => {
      this.blogPosts = res;
      this.postLoaded.next(true);
    });
  }

  getSinglePost(id) {
    let uri = '/admin/getArticle/' + id;

    this.apiHelper.get(uri, {}, true).subscribe(res => {
      this.singleBlog = res;
      this.singlePostLoaded.next(true);
    })
  }

  returnSinglePost() {
    return this.singleBlog;
  }

}