import {Component, ElementRef, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {Subject, BehaviorSubject} from 'rxjs';
import swal from 'sweetalert2'
import {SharedService} from '../../shared/shared.service';
import {ApiHelperService} from '../../shared/api-helper.service';
import * as $ from 'jquery';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import { PodcastsService } from '../podcasts.service';


@Component({
    selector: 'podcast-preview',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./podcast-preview.component.scss'],
    templateUrl: './podcast-preview.component.html',
    providers: [PodcastsService]
})

export class PodcastPreview {
    postCreated: BehaviorSubject<boolean> = new BehaviorSubject(false);
    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        minHeight: '5rem',
        //maxHeight: '15rem',
        height: '95rem',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Edit Your Content Here!',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '5',
        fonts: [
            {class: 'arial', name: 'Arial'},
            {class: 'times-new-roman', name: 'Times New Roman'},
            {class: 'calibri', name: 'Calibri'},
            {class: 'comic-sans-ms', name: 'Comic Sans MS'}
        ],
        customClasses: [
            {
                name: 'quote',
                class: 'quote',
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: 'titleText',
                class: 'titleText',
                tag: 'h1',
            },
        ],
        //uploadUrl: this.shared.returnUrl() + '/admin/addContentPhoto',
        uploadWithCredentials: false,
        sanitize: false,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
            ['bold', 'italic'],
            ['fontSize']
        ],

    };

    constructor(
        private podcastService: PodcastsService,
        private route: ActivatedRoute,
        private shared: SharedService,
        private element: ElementRef,
        private api: ApiHelperService
    ) {
        this.postCreated.subscribe(created => {
            if (created) {
                let svg = this.element.nativeElement.querySelector('.checkmark');

                svg.setAttribute("class", 'checkmark active');

                setTimeout(() => {
                    svg.setAttribute("class", 'checkmark');
                    this.postCreated.next(false);
                }, 2000)
            }
        })
    }

    id: number;
    private sub: any;
    private filter: any = {};

    public singlePost;
    public postLoaded = false;
    items = [];

    public userSavedPost = false;
    public uploadedImages = [];


    public form: any;
    public baseUrl = this.shared.returnUrl() + '/';

    public areaArray = [];


    onReady(editor) {
        //        editor.ui.getEditableElement().parentElement.insertBefore(
        //            editor.ui.view.toolbar.element,
        //            editor.ui.getEditableElement()
        //        );

    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.id = + params['id'];

            this.podcastService.getSinglePodcast(this.id);

            $(document).ready(() => {
                setInterval(() => {
                    var links = $("a[target='_blank']");

                    for (let x = 0; x < links.length; x++) {
                        if ($(links[x]).parent('p').length || $(links[x]).parent('li').length) {
                            //..
                        } else {
                            $(links[x]).hide();
                        }
                    }
                }, 500)
            })

            this.podcastService.singlePodcastLoaded.subscribe(loaded => {
                if (loaded) {
                    this.singlePost = this.podcastService.returnSinglePodcast();
                    console.log(JSON.stringify(this.singlePost.active));
                    if (this.singlePost.tags) { // Check if tags is not null or undefined
                        const tempTagObject = this.singlePost.tags.split(',');
                    
                        for (let n = 0; n < tempTagObject.length; n++) {
                            this.items.push({
                                display: tempTagObject[n],
                                value: tempTagObject[n]
                            });
                        }
                    } else {
                        console.warn("Tags are null or undefined"); // Optional: Handle the case when tags are null
                    }
                    

                    this.form = {
                        'id': this.singlePost.id,
                        'title': this.singlePost.title,
                        'slug': this.singlePost.slug,
                        'article': this.singlePost.article,
                        'active': this.singlePost.active,
                        'tags': this.items,
                        // 'featured_base64' : this.singlePost.thumbnail_image,
                        // 'cover_base64' : this.singlePost.cover_image,
                    }

                    this.postLoaded = true;

                    setTimeout(() => {

                        let image = this.element.nativeElement.querySelector('.cover-photo');

                        if (this.singlePost.cover_image) {
                            image.style.backgroundImage = 'url(' + this.baseUrl + this.singlePost.cover_image + ')';
                        }

                        let image2 = this.element.nativeElement.querySelector('.fourth-panel');

                        if (this.singlePost.thumbnail_image) {
                            image2.style.backgroundImage = 'url(' + this.baseUrl + this.singlePost.thumbnail_image + ')';
                        }

                    }, 1000)
                }
            })
        });

    }

    ngOnDestroy() {
        if (this.userSavedPost == false) {
            //            let uri = '/deleteUnpublishedPhotos'
            //            this.api.post(uri, this.uploadedImages, true).subscribe(res => {
            //                if (res) {
            //                    console.log('deleted')
            //                }
            //            })
        }
    }

  

    changeListner(event) {
        let reader = new FileReader();
        let image = this.element.nativeElement.querySelector('.cover-photo');
        let coverImage;

        reader.onload = function (e) {
            let target: any = e.target || e.srcElement
            image.style.backgroundImage = 'url(' + target.result + ')';
            coverImage = target.result;
        };

        setTimeout(() => {
            this.form['cover_image_base64'] = coverImage;
        }, 500)

        reader.readAsDataURL(event.target.files[0]);
    }

    changeListnerThumbnail(event) {
        let reader = new FileReader();
        let image = this.element.nativeElement.querySelector('.fourth-panel');
        let thumbImage;

        reader.onload = function (e) {
            let target: any = e.target || e.srcElement
            image.style.backgroundImage = 'url(' + target.result + ')';
            thumbImage = target.result;
        };

        setTimeout(() => {
            this.form['thumbnail_image_base64'] = thumbImage;
        }, 500)

        reader.readAsDataURL(event.target.files[0]);
    }

    public newTabUrl;

    updatePost(form, preview: boolean) {
        let uri = '/admin/updatePodcastArticle';
        let tagsToString = [];
        this.areaArray = [];

        for (let x = 0; x < this.items.length; x++) {
            console.log(this.items[x])
            tagsToString.push(this.items[x]['value']);
        }

        $('.checkedAof').each((index, elem: any) => {
            if ($(elem).is(':checked')) {
                this.areaArray.push($(elem).val())
            }
        });

        this.form['tags'] = tagsToString.join();

        this.api.post(uri, form, true).subscribe(res => {
            if (res.Success) {
                this.postCreated.next(true);
    
                swal.fire({
                    text: res.Success, // Use the success message from the response
                    icon: 'success',
                    timer: 1500,
                });
            } else if (res.Error) {
                swal.fire({
                    text: res.Error, // Use the error message from the response
                    icon: 'error',
                    timer: 1500,
                });
            }
        },
        error => {
            // Handle any unexpected errors
            swal.fire({
                text: 'An unexpected error occurred.',
                icon: 'error',
                timer: 1500,
            });
        });
    }

    submitPodcastPost(preview) {
        this.updatePost(this.form, preview);
        this.userSavedPost = true;
    }


    activatePost(id) {
        let uri = '/admin/publishPodcastArticle';

        let obj = {
            id: id
        }

        this.api.post(uri, obj, true).subscribe(res => {
            if (res) {
                this.singlePost.active = 1;

                swal.fire({
                    text: 'Article Activated! ',
                    icon: 'success',
                    timer: 1500,
                });
            }
        })
    }

    deactivatePost(id) {
        let uri = '/admin/unPublishPodcastArticle';

        let obj = {
            id: id
        }

        this.api.post(uri, obj, true).subscribe(res => {
            if (res) {

                this.singlePost.active = 0;

                swal.fire({
                    text: 'Article Deactivated! ',
                    icon: 'success',
                    timer: 1500,
                });
            }
        })
    }

    featuredUpdate() {
        let featuredCheckbox: any = document.getElementById('featuredCheck');

        if (featuredCheckbox.checked == true) {
            this.form.featured = 1;
        } else {
            this.form.featured = 0;
        }
    }
}
