import {Component, ElementRef, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BlogService} from '../blog.service';
import {Subject, BehaviorSubject} from 'rxjs';
import swal from 'sweetalert2'
import {SharedService} from '../../shared/shared.service';
import {ApiHelperService} from '../../shared/api-helper.service';
import * as $ from 'jquery';
import {AngularEditorConfig} from '@kolkov/angular-editor';


@Component({
    selector: 'blog-preview',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./blog-preview.component.scss'],
    templateUrl: './blog-preview.component.html',
    providers: [BlogService]
})

export class BlogPreview {
    postCreated: BehaviorSubject<boolean> = new BehaviorSubject(false);
    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        minHeight: '5rem',
        //maxHeight: '15rem',
        height: '95rem',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Edit Your Content Here!',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '5',
        fonts: [
            {class: 'arial', name: 'Arial'},
            {class: 'times-new-roman', name: 'Times New Roman'},
            {class: 'calibri', name: 'Calibri'},
            {class: 'comic-sans-ms', name: 'Comic Sans MS'}
        ],
        customClasses: [
            {
                name: 'quote',
                class: 'quote',
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: 'titleText',
                class: 'titleText',
                tag: 'h1',
            },
        ],
        //uploadUrl: this.shared.returnUrl() + '/admin/addContentPhoto',
        uploadWithCredentials: false,
        sanitize: false,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
            ['bold', 'italic'],
            ['fontSize']
        ],

    };

    //    editorConfig = {
    //        placeholderText: 'Edit Your Content Here!',
    // plugins: [Bold,Paragraph,Italic,ListStyle,List,Subscript,Underline,Superscript,Heading,SimpleUploadAdapter],
    //        toolbar: {
    //            items: [
    //                'heading', '|',
    //                'fontfamily', 'fontsize', '|',
    //                'alignment', '|',
    //                'fontColor', 'fontBackgroundColor', '|',
    //                'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
    //                'link', '|',
    //                'outdent', 'indent', '|',
    //                'bulletedList', 'numberedList', 'todoList', '|',
    //                'code', 'codeBlock', '|',
    //                'insertTable', '|',
    //                'uploadImage', 'blockQuote', '|',
    //                'undo', 'redo'
    //            ],
    //            shouldNotGroupWhenFull: true
    //        },
    //        image: {
    //            toolbar: [
    //                'imageStyle:full',
    //                'imageStyle:side',
    //                '|',
    //                'imageTextAlternative'
    //            ]
    //        },
    //        table: {
    //            contentToolbar: [
    //                'tableColumn',
    //                'tableRow',
    //                'mergeTableCells'
    //            ]
    //        },
    //        simpleUpload: {
    //            uploadUrl: this.shared.returnUrl() + '/admin/addContentPhoto',
    //            withCredentials: false,
    //            headers: {
    //                //'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    //                'Access-Control-Allow-Origin': 'http://localhost:4200/',
    //                'Access-Control-Allow-Methods':
    //                    'HEAD, OPTIONS, GET, POST, PUT, PATCH, DELETE',
    //                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    //                'Content-Type': 'multipart/form-data',
    //                'access-token': localStorage.getItem('auth_token'),
    //                'Access-Control-Allow-Credentials': true
    //            }
    //        },

    // This value must be kept in sync with the language defined in webpack.config.js.
    //        language: 'en'
    //    };

    constructor(
        private blogService: BlogService,
        private route: ActivatedRoute,
        private shared: SharedService,
        private element: ElementRef,
        private api: ApiHelperService
    ) {
        this.postCreated.subscribe(created => {
            if (created) {
                let svg = this.element.nativeElement.querySelector('.checkmark');

                svg.setAttribute("class", 'checkmark active');

                setTimeout(() => {
                    svg.setAttribute("class", 'checkmark');
                    this.postCreated.next(false);
                }, 2000)
            }
        })
    }

    id: number;
    private sub: any;
    private filter: any = {};

    public singlePost;
    public postLoaded = false;
    items = [];
    public allAOFs;

    public userSavedPost = false;
    public uploadedImages = [];

    //    public options: Object = {
    //        placeholderText: 'Edit Your Content Here!',
    //        pasteAllowedStyleProps: ['font-family', 'font-size', 'color', 'font-style'],
    //        charCounterCount: false,
    //        imageUploadURL: this.shared.returnUrl() + '/admin/addContentPhoto',
    //        requestHeaders: {
    //            'access-token': localStorage.getItem('auth_token')
    //        },
    //        events: {
    //            'froalaEditor.image.uploaded': (e, editor, response) => {
    //                // console.log(response)
    //                let tmp = JSON.parse(response)
    //                this.uploadedImages.push(response['link']);
    //            }
    //        }
    //    }

    public form: any;
    public baseUrl = this.shared.returnUrl() + '/';

    public areaArray = [];


    onReady(editor) {
        //        editor.ui.getEditableElement().parentElement.insertBefore(
        //            editor.ui.view.toolbar.element,
        //            editor.ui.getEditableElement()
        //        );

    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.id = + params['id'];

            this.blogService.getSinglePost(this.id);

            $(document).ready(() => {
                setInterval(() => {
                    var links = $("a[target='_blank']");

                    for (let x = 0; x < links.length; x++) {
                        if ($(links[x]).parent('p').length || $(links[x]).parent('li').length) {
                            //..
                        } else {
                            $(links[x]).hide();
                        }
                    }
                }, 500)
            })

            this.blogService.singlePostLoaded.subscribe(loaded => {
                if (loaded) {
                    this.singlePost = this.blogService.returnSinglePost();
                    console.log(JSON.stringify(this.singlePost.active));
                    let tempTagObject = this.singlePost.tags.split(',');

                    for (let n = 0; n < tempTagObject.length; n++) {
                        this.items.push({
                            display: tempTagObject[n],
                            value: tempTagObject[n]
                        })
                    }

                    this.form = {
                        'article_id': this.singlePost.id,
                        'title': this.singlePost.title,
                        'user_id': localStorage.getItem('user_id'),
                        'article': this.singlePost.article,
                        'active': this.singlePost.active,
                        'excerpt': this.singlePost.excerpt,
                        'tags': this.items,
                        'featured': this.singlePost.featured
                        // 'featured_base64' : this.singlePost.thumbnail_image,
                        // 'cover_base64' : this.singlePost.cover_image,
                    }

                    this.postLoaded = true;

                    setTimeout(() => {
                        // precheck checkboxes
                        $('.checkedAof').each((index, elem: any) => {
                            $.each(this.singlePost.areas_of_difficulty, function (index2, elem2) {
                                if ($(elem).val() == elem2.id) {
                                    $(elem).prop('checked', true);
                                }
                            })
                        });

                        let image = this.element.nativeElement.querySelector('.cover-photo');

                        if (this.singlePost.cover_image) {
                            image.style.backgroundImage = 'url(' + this.baseUrl + this.singlePost.cover_image + ')';
                        }

                        let image2 = this.element.nativeElement.querySelector('.fourth-panel');

                        if (this.singlePost.thumbnail_image) {
                            image2.style.backgroundImage = 'url(' + this.baseUrl + this.singlePost.thumbnail_image + ')';
                        }

                        if (this.singlePost.featured) {
                            $('#featuredCheck').prop('checked', true);
                        }
                    }, 1000)
                }
            })
        });

        this.getAofs();
    }

    ngOnDestroy() {
        if (this.userSavedPost == false) {
            //            let uri = '/deleteUnpublishedPhotos'
            //            this.api.post(uri, this.uploadedImages, true).subscribe(res => {
            //                if (res) {
            //                    console.log('deleted')
            //                }
            //            })
        }
    }

    getAofs() {
        let uri = '/admin/listTaxonomies';
        this.filter.type = 'area_of_difficulty';

        this.api.get(uri, this.filter, true)
            .subscribe(res => {
                if (res) {
                    this.allAOFs = res.data;
                }
            })
    }

    changeListner(event) {
        let reader = new FileReader();
        let image = this.element.nativeElement.querySelector('.cover-photo');
        let coverImage;

        reader.onload = function (e) {
            let target: any = e.target || e.srcElement
            image.style.backgroundImage = 'url(' + target.result + ')';
            coverImage = target.result;
        };

        setTimeout(() => {
            this.form['cover_base64'] = coverImage;
        }, 500)

        reader.readAsDataURL(event.target.files[0]);
    }

    changeListnerThumbnail(event) {
        let reader = new FileReader();
        let image = this.element.nativeElement.querySelector('.fourth-panel');
        let thumbImage;

        reader.onload = function (e) {
            let target: any = e.target || e.srcElement
            image.style.backgroundImage = 'url(' + target.result + ')';
            thumbImage = target.result;
        };

        setTimeout(() => {
            this.form['featured_base64'] = thumbImage;
        }, 500)

        reader.readAsDataURL(event.target.files[0]);
    }

    public newTabUrl;

    updatePost(form, preview: boolean) {
        let uri = '/admin/updateArticle';
        let tagsToString = [];
        this.areaArray = [];

        for (let x = 0; x < this.items.length; x++) {
            console.log(this.items[x])
            tagsToString.push(this.items[x]['value']);
        }

        $('.checkedAof').each((index, elem: any) => {
            if ($(elem).is(':checked')) {
                this.areaArray.push($(elem).val())
            }
        });

        this.form['area_of_difficulty'] = this.areaArray.join();
        this.form['tags'] = tagsToString.join();

        this.api.post(uri, form, true).subscribe(res => {
            this.postCreated.next(true);

            // this.newTabUrl = 'http://child-nexus-dev1.balsabozovic.com/blog/article/' + res.Article.slug + '?preview=true';
            this.newTabUrl = this.shared.returnWebsiteUrl() + '/blog/article/' + res.Article.slug + '?preview=' + preview;

            setTimeout(() => {
                (<HTMLElement> $('#openInNewTab')[0]).click();
            }, 500)
        })
    }

    submitBlogPost(preview) {
        this.updatePost(this.form, preview);
        this.userSavedPost = true;
    }


    activatePost(id) {
        let uri = '/admin/publishArticle';

        let obj = {
            article_id: id
        }

        this.api.post(uri, obj, true).subscribe(res => {
            if (res) {
                this.singlePost.active = 1;

                swal.fire({
                    text: 'Article Activated! ',
                    icon: 'success',
                    timer: 1500,
                });
            }
        })
    }

    deactivatePost(id) {
        let uri = '/admin/unPublishArticle';

        let obj = {
            article_id: id
        }

        this.api.post(uri, obj, true).subscribe(res => {
            if (res) {

                this.singlePost.active = 0;

                swal.fire({
                    text: 'Article Deactivated! ',
                    icon: 'success',
                    timer: 1500,
                });
            }
        })
    }

    featuredUpdate() {
        let featuredCheckbox: any = document.getElementById('featuredCheck');

        if (featuredCheckbox.checked == true) {
            this.form.featured = 1;
        } else {
            this.form.featured = 0;
        }
    }
}
