import {Component, Output, EventEmitter, ElementRef, HostListener, ViewEncapsulation} from '@angular/core';

import { AuthService } from '../../pages/shared/auth.service';
import { SharedService } from '../../pages/shared/shared.service';
import { ApiHelperService } from '../../pages/shared/api-helper.service';

@Component({
  selector: 'header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html'
})

export class HeaderComponent {
  
  constructor(
    private _elementRef:ElementRef,
    private auth: AuthService,
    private shared: SharedService,
    private apiHelper : ApiHelperService
  ) {
  }

  public user;
  public image;
  public showUserDropdown; 

  ngOnInit() {
    if (localStorage.getItem('auth_token')) {
      this.shared.userDataInformation.subscribe(loaded => {
        if (loaded) {
          this.user = this.shared.getData('userDetails');
          
          if (this.user.images && this.user.images.length) {
            this.image = this.shared.returnUrl() + '/' + this.user.images[0].img;
          } else {
            this.image = 'assets/default.png';
          }
        }
      });
    }
  }
  @Output('toggle') navToggle = new EventEmitter();

  toggleNavigation(e) {  
    console.log(e)
    this.navToggle.emit('my data to emit');
  }

  logoutUser() {
    this.auth.logout();
  }
  
  toggleDrop() {
   this.showUserDropdown = !this.showUserDropdown; 
   console.log(this.showUserDropdown)
  }
  
}

