import { Component, Input, Output ,EventEmitter} from '@angular/core';
import { ApiHelperService } from '../../shared/api-helper.service';
import * as Constants from '../../../CONSTANTS';

export interface BlogCardInterface {
  id: number,
  checked:boolean
}

@Component({
  selector: 'blog-card',
  templateUrl: 'blog-card.component.html',
  styleUrls: ['./blog-card.component.scss'],
})

export class BlogCardComponent {
  constructor(
    private api: ApiHelperService
  ) {
  }

  private base_url = Constants.URL_API;

  @Input() title;
  @Input() image;
  @Input() excerpt;
  @Input() id;
  @Input() featured;
  @Output() changeAction = new EventEmitter();
  public selected:boolean=false;
  
  

  ngOnInit() {
    this.appendUrl();
  }

  appendUrl() {
    let temp = this.base_url + '/' + this.image;

    this.image = temp;
  }

  featureArticle(id) {
    let uri = '/admin/setFeatured';

    let obj = {
      article_id : [id]
    }

    this.api.post(uri, obj, true)
      .subscribe(res => {
        this.featured = 1;
      })
  }

  unfeatureArticle(id) {
    let uri = '/admin/setUnFeatured';

    let obj = {
      article_id : [id]
    }

    this.api.post(uri, obj, true)
      .subscribe(res => {
        this.featured = 0;
      })
  }
  
  changeOnClick(id:number) {
    this.changeAction.emit({id:id, checked:this.selected});
  }
  
  
    
}