import {Component, ElementRef, ViewEncapsulation, OnInit, OnDestroy} from '@angular/core';

import {ApiHelperService} from './../shared/api-helper.service';
import {SharedService} from './../shared/shared.service';
import {IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';

@Component({
    selector: 'revenue',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./revenue.component.scss'],
    templateUrl: './revenue.component.html'
})

export class RevenueComponent {
    constructor(
        private api: ApiHelperService,
        private shared: SharedService
    ) {
    }

    public revenueData: any;
    public revenueTotal: any;
    public fromDate: any;
    public toDate: any;
    public perPage: any = 25;
    public filter: any = {};
    public stats: any;
    public p: number = 1;

    myDatePickerOptions: IAngularMyDpOptions = {
        dateRange: false,
        dateFormat: 'yyyy-mm-dd'
        // other options are here...
    };


    model: IMyDateModel = null;
    model2: IMyDateModel = null;

    ngOnInit() {
        this._getRevenueData(this.filter);
        this.stats = this.shared.getData('globalStats');
        this.model = {isRange: false, singleDate: {jsDate: new Date()}};
        this.model2 = {isRange: false, singleDate: {jsDate: new Date()}};
    }

    ngOnDestroy() {
        // console.log('destroy')
    }

    onDateChanged(event: IMyDateModel) {
        // event properties are: event.date, event.jsdate, event.formatted and event.epoc
        this.fromDate = event.singleDate.formatted;
    }

    onEndDateChanged(event: IMyDateModel) {
        // event properties are: event.date, event.jsdate, event.formatted and event.epoc
        this.toDate = event.singleDate.formatted;
    }

    public _getRevenueData(filter) {
        let uri = '/admin/listPayments';

        if (!filter) {
            filter = {}
        }

        this.api.get(uri, filter, true)
            .subscribe(res => {
                if (res) {
                    this.revenueData = res.data;
                    this.revenueTotal = res.total;
                }
            });
    }

    public filterRevenue(type, value) {
        this.filter[type] = value;

        this._getRevenueData(this.filter);
    }

    public searchRevs(event) {
        this.filter.keyword = event;

        this._getRevenueData(this.filter);
    }

    public sortRevenueList(value) {
        this.filter.sort = value;

        this._getRevenueData(this.filter);
    }

    public runReports() {
        this.filter.fromDate = this.fromDate;
        this.filter.toDate = this.toDate;

        this._getRevenueData(this.filter);
    }

    public showPerPage(value) {
        this.filter.per_page = value;

        this.perPage = value;

        this._getRevenueData(this.filter);
    }

    pageChange(page) {
        this.filter.page = page;
        this._getRevenueData(this.filter);
    }

    resetFilter() {
        this.filter = {};

        this._getRevenueData(this.filter);

        // not the best way
        $("#revenue-filter").val("default");
        $("#cons-user-filter").val("default");
        $("#cons-user-location-filter").val("default");
    }
}