import {Component, Renderer2, AfterViewInit, ViewEncapsulation} from '@angular/core';
import {IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';

import {ApiHelperService} from '../../shared/api-helper.service';
import {SharedService} from '../../shared/shared.service';

@Component({
    selector: 'bar-chart',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss'],
})

export class BarChartComponent {

    constructor(
        private render: Renderer2,
        private apiHelper: ApiHelperService,
        private shared: SharedService
    ) {
        this.getAllStats();
        this.getSessionStats();
        this.getUserStats();
    }

    public barChartLabels: string[] = [];
    public barChartType: string = 'bar';
    public barChartLegend: boolean = true;
    public barChartDataLoaded = false;


    myDatePickerOptions: IAngularMyDpOptions = {
        dateRange: false,
        dateFormat: 'yyyy-mm-dd'
        // other options are here...
    };


    model: IMyDateModel = null;
    model2: IMyDateModel = null;

    public barChartData: any[] = [{
        data: [],
        label: 'New User',
        hoverBackgroundColor: '#1ab0e4',
        backgroundColor: '#FFA500'
    }
    ];

    public globalStats;
    public fromDate: any;
    public toDate;
    public filter: any = {};

    public currentFilter: string = 'User';
    public active_span = 1;

    public barChartOptions: any = {
        scaleShowVerticalLines: false,
        responsive: true,
        maintainAspectRatio: false,
        height: '400px',
        legend: false,
        backgroundColor: '#4ecdc4',
        tooltips: {
            custom: function (tooltip) {
                // tooltip will be false if tooltip is not visible or should be hidden
                if (!tooltip) {
                    return;
                }

                // Otherwise, tooltip will be an object with all tooltip properties like:

                tooltip.caretSize = 5,
                    tooltip.text = 'Users'
                // tooltip.caretPadding
                // tooltip.chart
                // tooltip.cornerRadius
                // tooltip.fillColor = 'red',
                // tooltip.font = 'cursive',
                // tooltip.x
                // tooltip.y
                // tooltip.caretX
                // tooltip.caretY
                // etc...
            }
        }
    };

    private _getChartData(filter?) {
        let uri = '/admin/getChartStats';
        let params;

        if (!filter) {
            params = {}
        } else {
            params = filter;
        }

        this.apiHelper.get(uri, params, true).subscribe(res => {
            this.barChartLabels = res.barChartLabels;
            this.barChartData[0].data = res.data

            this.fromDate = res.barChartLabels[0];
            this.toDate = res.barChartLabels[res.barChartLabels.length - 1];

            if (params.metrics == 'revenue') {
                this.barChartData[0].label = 'Revenue';
                this.currentFilter = 'Revenue';
            } else {
                this.barChartData[0].label = 'User';
                this.currentFilter = 'User';
            }
        });
    }

    ngOnInit() {
        this.model = {isRange: false, singleDate: {jsDate: new Date()}};
        this.model2 = {isRange: false, singleDate: {jsDate: new Date()}};
    }

    onDateChanged(event: IMyDateModel) {
        // event properties are: event.date, event.jsdate, event.formatted and event.epoc
        this.fromDate = event.singleDate.formatted;
        this.dateUpdate();
    }

    onEndDateChanged(event: IMyDateModel) {
        this.toDate = event.singleDate.formatted;
         this.dateUpdate();
    }

    ngAfterViewInit() {
        this._getChartData()
    }

    changeType(filterName, filterValue, resetFilter?) {
        this.filter[filterName] = filterValue;

        this._getChartData(this.filter)
    }

    dateUpdate() {
        this.filter.fromDate = this.fromDate;
        this.filter.toDate = this.toDate;

        this._getChartData(this.filter);
        this.getSessionStats(this.sessionFilterObj['parametar'], this.fromDate, this.toDate);
        this.getUserStats(this.fromDate, this.toDate);
    }

    // events
    public chartClicked(e: any): void {
        console.log(e);
    }

    public chartHovered(e: any): void {
        // console.log(e);
    }

    getAllStats() {
        let uri = "/admin/getGlobalStats";

        this.apiHelper.get(uri, {}, true).subscribe(res => {
            this.globalStats = res[0];
        })
    }

    public userStats;

    getUserStats(fromDate?, toDate?) {
        let uri = "/admin/getUserDataStats";

        let obj: any = {};

        if (fromDate) {
            obj['fromDate'] = fromDate
        }

        if (toDate) {
            obj['toDate'] = toDate;
        }

        this.apiHelper.get(uri, obj, true).subscribe(res => {
            this.userStats = res;
            this.userStats.bounce_rate_percentage = Math.trunc(this.userStats.bounce_rate);
            this.userStats.new_members.percentage_percentage = Math.trunc(this.userStats.new_members.percentage);
            this.userStats.new_users.percentage_percentage = Math.trunc(this.userStats.new_users.percentage);
        })
    }

    public sessionStats: any;
    public sessionFilterName: any = 'City';
    public sessionFilterObj: any = {};

    getSessionStats(filter?, fromDate?, toDate?) {
        // ?parametar=city
        let uri = '/admin/getListStats';

        let filterObj: any = {};

        if (!filter) {
            this.sessionFilterObj['parametar'] = 'city'
        } else {
            this.sessionFilterObj['parametar'] = filter;
        }

        if (fromDate && toDate) {
            this.sessionFilterObj.fromDate = fromDate;
            this.sessionFilterObj.toDate = toDate;
        }

        if (filter == 'city') this.sessionFilterName = 'City';
        if (filter == 'browser') this.sessionFilterName = 'Browser';
        if (filter == 'page') this.sessionFilterName = 'Pages';
        if (filter == 'search_term') this.sessionFilterName = 'Search Term';

        this.apiHelper.get(uri, this.sessionFilterObj, true)
            .subscribe(res => {
                this.sessionStats = res;
            })
    }
}