import { Component, OnInit } from '@angular/core';
import { PodcastsService } from './podcasts.service';
import swal from 'sweetalert2'
import { ApiHelperService } from '../shared/api-helper.service';

@Component({
  selector: 'app-podcasts',
  templateUrl: './podcasts.component.html',
  styleUrls: ['./podcasts.component.scss']
})
export class PodcastsComponent implements OnInit {
  podcasts: any[] = [];
  isLoading: boolean = true;
  public numberOfArticles = null;
  public currentPage = 1;
  private filter: any = {};
  public p: number = 1;

  constructor(private podcastsService: PodcastsService,
    private api: ApiHelperService
  ) { }

  ngOnInit(): void {
    this.loadPodcasts();
  }

  changeOnClick(e: any) {
    if (e.checked == true) {
        this.checkedArticles.push(e.id.toString())
    } else {
        for (let i = 0; i < this.checkedArticles.length; i++) {
            if (this.checkedArticles[i] == e.id.toString()) {
                var index = this.checkedArticles.indexOf(this.checkedArticles[i]);
                this.checkedArticles.splice(index, 1);
                break;
            }
        }
    }
}

  loadPodcasts(page?: number, filter?: object): void {
    this.podcastsService.getPodcastList(page, filter);
    this.podcastsService.podcastsLoaded.subscribe(loaded => {
      if (loaded) {

        this.numberOfArticles = this.podcastsService.podcastList['total'];
        this.podcasts = this.podcastsService.podcastList.data;
        this.isLoading = false;
      }
    });
  }

  pageChange(page) {
    this.podcastsService.getPodcastList(page);
    this.currentPage = page;

  }

  toggleActive(value) {
    this.filter.active = value;
    if (this.filter['new']) {
        delete this.filter['new']
    }
    if (this.filter['featured']) {
        delete this.filter['featured']
    }

    this.podcastsService.getPodcastList(this.currentPage, this.filter);

}

  sort(value) {
    this.filter.order = value;
    this.podcastsService.getPodcastList(this.currentPage, this.filter);
  }

  searchPodcasts(event) {
    this.filter.keyword = event;
    this.podcastsService.getPodcastList(this.currentPage, this.filter);

  }

  resetFilter() {
    this.podcastsService.getPodcastList(this.currentPage, {});
  }

  public checkedArticles: any = [];

  selectAll(value) {
      var self = this;
      if (value == 1) {
          $('.single-checkbox').each(function (index, elem) {
              $(elem).prop('checked', true);
              self.checkedArticles.push($(elem).val())
          });
      } else {
          $('.single-checkbox').each(function (index, elem) {
              $(elem).prop('checked', false);
          });
          self.checkedArticles = [];
      }
  }

  activateArticles(event) {
    let uri = '/admin/publishPodcastArticle';

    if (this.checkedArticles.length) {
        let obj = {
            article_id: this.checkedArticles
        }

        this.api.post(uri, obj, true).subscribe(res => {
            this.checkedArticles = [];
            $('.single-checkbox').each(function (index, elem) {
                $(elem).prop('checked', false);
            });
            this.podcastsService.getPodcastList();
            this.loadPodcasts();

            swal.fire({
                text: 'Articles Activated! ',
                icon: 'success',
                timer: 1500,
            });
        })
    }
}

toggleFilter(name, value) {
    this.filter[name] = value;

    if (this.filter['active']) {
        delete this.filter['active']
    }

    this.podcastsService.getPodcastList(this.currentPage, this.filter);

}


deleteArticle() {
    let uri = '/admin/deletePodcastArticle';

    if (this.checkedArticles.length) {

        let obj = {
            id: this.checkedArticles
        }

        //        for (let i = 0; i < this.checkedArticles.length; i++) {
        //          for (let x = 0; x < this.podcastPosts.length; x++) {
        //            if (this.checkedArticles[i] == this.podcastPosts[x].id) {
        //              console.log(x, i)
        //              this.podcastPosts.splice(this.podcastPosts[x], 1); 
        //            }
        //          }
        //        }  

        this.api.post(uri, obj, true).subscribe(res => {
            this.podcastsService.getPodcastList();
            this.loadPodcasts();
            this.checkedArticles = [];
            $('.single-checkbox').each(function (index, elem) {
                $(elem).prop('checked', false);
            });

        })
    }
}



showPromptModal(event) {
    if (this.checkedArticles.length) {
        swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure want to delete ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                this.deleteArticle();
                swal.fire({
                    title: 'Deleted!',
                    text: 'Articles Deleted!',
                    icon: 'success'
                });
            }
        });
    } else {
        swal.fire({
            text: 'Select one or more articles to delete ',
            icon: 'info',
        });
    }
}
}
