import { Component, ViewEncapsulation } from '@angular/core';

import { ApiHelperService } from '../shared/api-helper.service';
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'dashboard',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./dashboard.component.scss'],
  templateUrl: './dashboard.component.html'
})

export class DashboardComponent {
    constructor(
      private api : ApiHelperService,
      private shared: SharedService
    ) {
      this.getAllStats();
    }

    public globalStats;
    public members;

    getAllStats() {
      let uri = "/admin/getGlobalStats";

      this.api.get(uri, {}, true)
      .subscribe(res => {
        this.globalStats = res;
        this.shared.replaceData(res, 'globalStats');
      })
    }
}