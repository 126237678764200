import {Component, ElementRef, ViewEncapsulation} from '@angular/core';
import {IMyOptions, IMyDateModel} from 'angular-mydatepicker';

import { ApiHelperService } from '../shared/api-helper.service';
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'statistic',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./statistic.component.scss'],
  templateUrl: './statistic.component.html'
})

export class StatisticComponent {
    constructor(
      private apiHelper: ApiHelperService,
      private shared: SharedService       
    ) {
    }

    onDateChanged(event: IMyDateModel) {
        // event properties are: event.date, event.jsdate, event.formatted and event.epoc
    }

    // /admin/getChartStats
}