import {Component,} from '@angular/core';

@Component({
  selector: 'footer-component',
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.component.html'
})

export class FooterComponent {
  
  constructor() {
  }
}