import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiHelperService } from '../shared/api-helper.service';
import { SharedService } from '../shared/shared.service';

@Injectable()
export class PodcastsService {

  private userDetails;
  podcastList: any;
  singlePodcast: any;
  podcastsLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  singlePodcastLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private apiHelper: ApiHelperService,
    private shared: SharedService
  ) {
    this.shared.userDataLoaded.subscribe(loaded => {
      if (loaded) {
        this.userDetails = this.shared.getData('user');
      }
    });
  }

  returnUser() {
    return this.userDetails;
  }

  getPodcastList(page?: number, filter?: object) {
    const uri = '/admin/listPodcasts';
    let params = {};

    if (page) {
      params = { page: page };
    }

    if (filter) {
      Object.assign(params, filter);
    }

    this.apiHelper.get(uri, params, true).subscribe(res => {
      this.podcastList = res;
      this.podcastsLoaded.next(true);
    });
  }

  getSinglePodcast(id: number) {
    const uri = `/admin/getPodcast/${id}`;

    this.apiHelper.get(uri, {}, true).subscribe(res => {
      this.singlePodcast = res;
      this.singlePodcastLoaded.next(true);
    });
  }

  returnSinglePodcast() {
    return this.singlePodcast;
  }
}
