import { Component, Input, Output } from '@angular/core';

@Component({
  selector: 'tile',
  templateUrl: 'tile.component.html',
  styleUrls: ['./tile.component.scss'],
})

export class TileComponent {
    constructor() {

    }

    @Input() data;
}