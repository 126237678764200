import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject, BehaviorSubject } from 'rxjs';

import { ApiHelperService } from '../../shared/api-helper.service';
import { SharedService } from '../../shared/shared.service';

@Injectable()
export class BlogPostService {

  private userDetails;
  postCreated: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
   private apiHelper: ApiHelperService,
   private shared : SharedService
  ) {
    this.shared.userDataLoaded.subscribe(loaded => {
      if (loaded) {
        this.userDetails = this.shared.getData('user');
      }
    })
  }

  returnUser() {
    return this.userDetails;
  }

  addNewPost(form) {
    let uri = '/admin/addArticle';

    this.apiHelper.post(uri, form, true).subscribe(res => {
      this.postCreated.next(true);
    })
  }

}