import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';
import { Observable }  from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private user: AuthService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot
  ): Observable <boolean> | boolean { 
		if (localStorage.getItem('auth_token')) {
      return true;
    } else {
		  this.router.navigate(['/login']);
		  return false;
    }
  }
}