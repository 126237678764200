import { Component, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

// import { AuthService } from '../../pages/shared/auth.service';
import { SharedService } from '../../../pages/shared/shared.service';
import { ApiHelperService } from '../../../pages/shared/api-helper.service';

@Component({
  selector: 'edit-tax',
  styleUrls: ['./edit-tax.component.scss'],
  templateUrl: './edit-tax.component.html'
})

export class EditTaxonomyComponent {
  
  constructor(
    private shared: SharedService,
    private api: ApiHelperService,
    private element: ElementRef,
    private activatedRoute: ActivatedRoute,
  ) {}

  public taxonomyDetails:any;
  public imageOne = 'assets/default.png';
  public imageTwo = 'assets/default.png';
  public taxonomyCreated = false;
  public color = '#ffffff';

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      let taxonomyId = params['id'];
      this.form['taxonomy_id'] = taxonomyId;
      this.getSingleTaxonomy(taxonomyId);
    });
  }

  onChangeType(value) {
    this.form.taxonomy_type = value;
  }

  onChangeColor(event) {
    this.color = event;
  }

  public form: any = {
		taxonomy_id: null, name: "", description: "", image1: "", image2: "", 
		color: "", taxonomy_type: 0
	};
  

  getSingleTaxonomy(id) {
    let uri = '/admin/getTaxonomy/' + id;

    this.api.get(uri, {}, true).subscribe(res => {
      if (res) {
        this.form.name = res.name;
        this.form.description = res.description;

        if (res.color) {
          this.color = res.color;
          this.form.color = res.color;
        }
        
        this.form.taxonomy_type = res.taxonomy_type;
        if (res.image1) {
          this.imageOne = this.shared.returnUrl() + '/' + res.image1  
        } 

        if (res.image2) {
          this.imageTwo = this.shared.returnUrl() + '/' + res.image2 
        }

        $("#taxSel").val(this.form.taxonomy_type);
      }
    })
  }
  
  submitTaxonomy() {
    let uri = '/admin/updateTaxonomy';

    this.form.color = this.color;

    this.api.post(uri, this.form, true).subscribe(res => {
      if (res) {
        this.taxonomyCreated = true;

        setTimeout(() => {
          this.taxonomyCreated = false;
        }, 2000);
      }
    })
  }

  addPhoto(event) {
    let target = event.target || event.srcElement;
    let photos = target.files;

    const reader = new FileReader();
    const image = this.element.nativeElement.querySelector('#img-upload');

    reader.onload = (e: any) => {
        let src = e.target.result;
        image.src = src;
        this.form.image1 = src;
    };

    setTimeout(function() {
      reader.readAsDataURL(target.files[0]);
    }, 1000) 
  }

  addPhotoTwo(event) {
    let target = event.target || event.srcElement;
    let photos = target.files;

    const reader = new FileReader();
    const image = this.element.nativeElement.querySelector('#img-upload-two');

    reader.onload = (e: any) => {
        let src = e.target.result;
        image.src = src;
        this.form.image2 = src;
    };

    setTimeout(function() {
      reader.readAsDataURL(target.files[0]);
    }, 1000) 
  }
}
