import { Component } from '@angular/core';
import { FormsModule, FormGroup, AbstractControl, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';

import { AuthService } from '../shared/auth.service';
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class Login {

  public form:FormGroup;
  public email:AbstractControl;
  public password:AbstractControl;
  public submitted:boolean = false;
  public showLoginError:boolean = false;

  constructor(
    fb:FormBuilder,
    private auth: AuthService,
    private shared: SharedService
  ) {
    this.form = fb.group({
      'email': ['', Validators.compose([Validators.required, Validators.minLength(4)])],
      'password': ['', Validators.compose([Validators.required, Validators.minLength(4)])]
    });

    this.email = this.form.controls['email'];
    this.password = this.form.controls['password'];

    this.shared.showLoginError.subscribe(showError => {
      if (showError) {
        this.showLoginError = true;
        this.shared.showLoginError.next(false);
      }
    })
  }

  public onSubmit(values:Object):void {
    this.submitted = true;
    if (this.form.valid) {
      this.auth.submitLoginData(values); 
    }
  }
}