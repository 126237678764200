import {Component} from '@angular/core';

// import { AuthService } from '../../pages/shared/auth.service';
import { SharedService } from '../../pages/shared/shared.service';
import { ApiHelperService } from '../../pages/shared/api-helper.service';

@Component({
  selector: 'taxonomy',
  styleUrls: ['./taxonomy.component.scss'],
  templateUrl: './taxonomy.component.html'
})

export class TaxonomyComponent {
  
  constructor(
    private shared: SharedService,
    private api: ApiHelperService
  ) {}

  public taxonomyList:any = [];
  public taxDeleted = false;
  public showDeleteModal;
  
  public numberOfTaxonomies;
  public per_page = 25;

  public currentPage = 1;
  public currentFilter = null;

  // id to delete user
  private storeTaxId;

  // filter
  public filter:any = {};
  public p: number = 1;

  ngOnInit() {
    this.getAllTaxonomies();
  }

  getAllTaxonomies(page?, filter?) {
    let uri = '/admin/listTaxonomies';

    let params:any = {};

    if (page) {
      params.page = page;
    } else {
      params.page = this.currentPage;
    }

    if (filter && filter.type) {
      params.type = filter.type;
      this.currentFilter = filter.type;
    }

    if (filter && filter.per_page) {
      params.per_page = filter.per_page;  
      this.per_page = filter.per_page;
    }

    this.api.get(uri, params, true).subscribe(res => {
      if (res) {
        this.taxonomyList = res.data;
        this.numberOfTaxonomies = res.total;

        for (let x = 0; x < this.taxonomyList.length; x++) {
          this.taxonomyList[x].taxonomy_type = this.taxonomyList[x].taxonomy_type.replace(/_/g, ' '); 
        }
      }
    })
  }

  setPerPage(value) {
    this.filter.per_page = value;

    this.getAllTaxonomies(this.currentPage, this.filter)
  }

  filterTax(filterValue) {
    this.filter.type = filterValue

    this.getAllTaxonomies(this.currentPage, this.filter)
  }

  searchTaxonomy(event) {
    // if (event.length < 3) return
    let uri = '/admin/listTaxonomies';

    this.filter.keyword = event;

    this.api.get(uri, this.filter, true)
    .subscribe(res => {
      if (res) {
        this.taxonomyList = res.data;
      }
    }) 
  }

  prompt(id) {
     this.showDeleteModal = true;
     this.storeTaxId = id;
  }

  pageChange(page) {
    this.currentPage = page;
    console.log('current: ' + this.currentPage)
    this.getAllTaxonomies(page, this.filter);
  }

  confirmDelete(action) {
    if (action == 'yes') {
      this.deleteTaxonomy(this.storeTaxId);
    } else {
      this.storeTaxId = null;
      this.showDeleteModal = false;
    }
   }

  deleteTaxonomy(id) {
    let uri = '/admin/deleteTaxonomy';

    let obj = {
      taxonomy_id : id
    }

    this.api.post(uri, obj, true).subscribe(res => {
      if (res) {
        this.taxDeleted = true;
        this.showDeleteModal = false;
        this.getAllTaxonomies();

        setTimeout(() => {
          this.taxDeleted = false;
        }, 2000)
      }
    })
  }
}

