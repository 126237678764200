import {Injectable} from '@angular/core';

@Injectable()
export class FeedService {

  private _data = [
    {
      type: 'text-message',
      author: 'https://api.adorable.io/avatars/285/abott@adorable.io.png',
      authorname: 'Jose',
      surname: 'Danovsky',
      header: 'Profile Registration',
      text: 'Guys, check this out:',
      time: '1 day ago',
      ago: '25 minutes ago',
      expanded: false,
    }, {
      type: 'video-message',
      author: 'https://api.adorable.io/avatars/285/abott@adorable.io.png',
      authorname: 'Jose',
      surname: 'Hrabouski',
      header: 'Added new video',
      text: '"Vader and Me"',
      preview: 'app/feed/vader-and-me-preview.png',
      link: 'https://www.youtube.com/watch?v=IfcpzBbbamk',
      time: '1 day ago',
      ago: '3 hrs ago',
      expanded: false,
    }, {
      type: 'image-message',
      author: 'https://api.adorable.io/avatars/285/abott@adorable.io.png',
      authorname: 'Jose',
      surname: 'Lugovsky',
      header: 'Added new image',
      text: '"My little kitten"',
      preview: 'app/feed/my-little-kitten.png',
      link: 'http://api.ning.com/files/DtcI2O2Ry7A7VhVxeiWfGU9WkHcMy4WSTWZ79oxJq*h0iXvVGndfD7CIYy-Ax-UAFCBCdqXI4GCBw3FOLKTTjQc*2cmpdOXJ/1082127884.jpeg',
      time: '1 day ago',
      ago: '10 hrs ago',
      expanded: false,
    }, {
      type: 'text-message',
      author: 'https://api.adorable.io/avatars/285/abott@adorable.io.png',
      authorname: 'Jose',
      surname: 'Linnie',
      header: 'Posted new message',
      text: 'Haha lol',
      time: '1 day ago',
      ago: '2 days ago',
      expanded: false,
    }, {
      type: 'geo-message',
      author: 'https://api.adorable.io/avatars/285/abott@adorable.io.png',
      authorname: 'Jose',
      surname: 'Cat',
      header: 'Posted location',
      text: '"New York, USA"',
      preview: 'app/feed/new-york-location.png',
      link: 'https://www.google.by/maps/place/New+York,+NY,+USA/@40.7201111,-73.9893872,14z',
      time: '1 day ago',
      ago: '2 days ago',
      expanded: false,
    }, {
      type: 'text-message',
      author: 'https://api.adorable.io/avatars/285/abott@adorable.io.png',
      authorname: 'Jose',
      surname: 'Lugovsky',
      header: 'Posted new message',
      text: "First snake: I hope I'm not poisonous. Second snake: Why? First snake: Because I bit my lip!",
      time: '1 day ago',
      ago: '3 days ago',
      expanded: false,
    }, {
      type: 'text-message',
      author: 'https://api.adorable.io/avatars/285/abott@adorable.io.png',
      authorname: 'Jose',
      surname: 'Hrabouski',
      header: 'Posted new message',
      text: 'How do you smuggle an elephant across the border? Put a slice of bread on each side, and call him "lunch".',
      time: '1 day ago',
      ago: '5 days ago',
      expanded: false,
    }, {
      type: 'text-message',
      author: 'https://api.adorable.io/avatars/285/abott@adorable.io.png',
      authorname: 'Jose',
      surname: 'Linnie',
      header: 'Posted new message',
      text: 'When your hammer is C++, everything begins to look like a thumb.',
      time: '1 day ago',
      ago: '5 days ago',
      expanded: false,
    }, {
      type: 'text-message',
      author: 'https://api.adorable.io/avatars/285/abott@adorable.io.png',
      authorname: 'Jose',
      surname: 'Demeshko',
      header: 'Posted new message',
      text: '“I mean, they say you die twice. One time when you stop breathing and a second time, a bit later on, when somebody says your name for the last time." ©',
      time: '1 day ago',
      ago: '6 days ago',
      expanded: false,
    }, {
      type: 'image-message',
      author: 'https://api.adorable.io/avatars/285/abott@adorable.io.png',
      authorname: 'Jose',
      surname: 'Cat',
      header: 'Posted photo',
      text: '"Protein Heroes"',
      preview: 'app/feed/genom.png',
      link: 'https://dribbble.com/shots/2504810-Protein-Heroes',
      time: '1 day ago',
      ago: '7 days ago',
      expanded: false,
    },
    {
      type: 'text-message',
      author: 'https://api.adorable.io/avatars/285/abott@adorable.io.png',
      authorname: 'Jose',
      surname: 'Danovsky',
      header: 'Posted new message',
      text: 'Why did the CoffeeScript developer keep getting lost? Because he couldn\'t find his source without a map',
      time: '1 day ago',
      ago: '9 days ago',
      expanded: false,
    }
  ];

  getData() {
    return this._data;
  }
}
