import {Component, ElementRef, ViewEncapsulation, AfterViewInit} from '@angular/core';
import {FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {SharedService} from '../../shared/shared.service';
import {ApiHelperService} from '../../shared/api-helper.service';
import {Subject, BehaviorSubject} from 'rxjs';
import * as $ from 'jquery';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import { PodcastPostService } from './podcast-post.service';


@Component({
    selector: 'podcast-post',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./podcast-post.component.scss'],
    templateUrl: './podcast-post.component.html',
    providers: [PodcastPostService]
})

export class PodcastPostComponent {
    private user;
    items = [];
    private filter: any = {};
    public allAOFs;
    postCreated: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public userSavedPost = false;
    public uploadedImages = [];
    public newTabUrl = '';
    public self: any;
    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        minHeight: '5rem',
        //maxHeight: '15rem',
        height: '95rem',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Edit Your Content Here!',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '5',
        fonts: [
            {class: 'arial', name: 'Arial'},
            {class: 'times-new-roman', name: 'Times New Roman'},
            {class: 'calibri', name: 'Calibri'},
            {class: 'comic-sans-ms', name: 'Comic Sans MS'}
        ],
        customClasses: [
            {
                name: 'quote',
                class: 'quote',
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: 'titleText',
                class: 'titleText',
                tag: 'h1',
            },
        ],
        //uploadUrl: this.shared.returnUrl() + '/admin/addContentPhoto',
        uploadWithCredentials: false,
        sanitize: false,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
            ['bold', 'italic'],
            ['fontSize']
        ],

    };

    constructor(
        private shared: SharedService,
        private element: ElementRef,
        private podcastService: PodcastPostService,
        private api: ApiHelperService,
        private router: Router
    ) {
        this.shared.userDataLoaded.subscribe(loaded => {
            if (loaded) {
                this.user = this.shared.getData('user');
                this.form.user_id = this.user.id;
            }
        });


        this.postCreated.subscribe(created => {
            if (created) {
                let svg = this.element.nativeElement.querySelector('.checkmark');

                svg.setAttribute("class", 'checkmark active');

                setTimeout(() => {
                    svg.setAttribute("class", 'checkmark');
                }, 2000)
            }
        })
    }

    onReady(eventData) {
        console.log(JSON.stringify(eventData));

    }

    ngOnInit() {

        $(document).ready(() => {
            setInterval(() => {
                var links = $("a[target='_blank']");

                for (let x = 0; x < links.length; x++) {
                    if ($(links[x]).parent('p').length || $(links[x]).parent('li').length) {
                        //..
                    } else {
                        $(links[x]).hide();
                    }
                }
            }, 500)
        })
    }

    ngOnDestroy() {
        if (this.userSavedPost == false) {
            //      let uri = '/admin/deleteUnpublishedPhotos'
            //      this.api.post(uri, this.uploadedImages, true).subscribe(res => {
            //        if (res) {
            //          console.log('deleted')
            //        }
            //      })
        }
    }

    

    public areaArray = [];

    public form: any = {
        'title': '',
        'article': '',
        'slug': '',
        'active': 1,
        'tags': '',
        'thumbnail_image_base64': '',
        'cover_image_base64': ''
    }

    submitPodcastPost(preview?) {
        // adjust tags
        let tagsToString = [];
        this.areaArray = [];

        for (let x = 0; x < this.items.length; x++) {
            tagsToString.push(this.items[x].value);
        }

        // collect checked values
        $('.checkedAof').each((index, elem: any) => {
            if ($(elem).is(':checked')) {
                this.areaArray.push($(elem).val())
            }
        });

        this.form['tags'] = tagsToString.join();
        this.addNewPost(this.form, preview);
        this.userSavedPost = true;
    }

    addNewPost(form, preview?) {
        let uri = '/admin/addPodcastArticle';

        this.api.post(uri, form, true).subscribe(res => {
            this.postCreated.next(true);
         

            this.router.navigateByUrl('podcasts');
        })
    }

    submitNonActivePost() {
        this.form.active = 0;
        this.submitPodcastPost();
    }

    changeListner(event) {
        let reader = new FileReader();
        let image = this.element.nativeElement.querySelector('.cover-photo');
        let coverImage;

        reader.onload = function (e) {
            let target: any = e.target || e.srcElement
            image.style.backgroundImage = 'url(' + target.result + ')';
            coverImage = target.result;
        };

        setTimeout(() => {
            this.form['cover_image_base64'] = coverImage;
        }, 500)

        reader.readAsDataURL(event.target.files[0]);
    }

    changeListnerThumbnail(event) {
        let reader = new FileReader();
        let image = this.element.nativeElement.querySelector('.fourth-panel');
        let thimbImage;

        reader.onload = function (e) {
            let target: any = e.target || e.srcElement
            image.style.backgroundImage = 'url(' + target.result + ')';
            thimbImage = target.result;
        };

        setTimeout(() => {
            this.form['thumbnail_image_base64'] = thimbImage;
        }, 500)

        reader.readAsDataURL(event.target.files[0]);
    }

}

